import { getGeolocation } from "../../../../lib/get-geolocation";

declare global {
  interface Window {
    google: any;
  }
}

// API Response types
interface GooglePrediction {
  description: string;
  place_id: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
}

// Our transformed suggestion type
export interface AddressSuggestion {
  description: string;
  placeId: string;
  mainText: string;
  secondaryText: string;
}

// Return type for our service
export interface AddressSuggestionsResponse {
  data: AddressSuggestion[] | null;
  error: string | null;
}

// Load Google Places script once
const loadGooglePlacesScript = (apiKey: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (window.google?.places) {
      resolve();
      return;
    }
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = () => resolve();
    script.onerror = () =>
      reject(new Error("Failed to load Google Places script"));
    document.head.appendChild(script);
  });
};

export async function getAddressSuggestions(
  input: string
): Promise<AddressSuggestionsResponse> {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  if (!apiKey) {
    return {
      data: null,
      error: "Missing API Key",
    };
  }

  if (!input) {
    return { data: [], error: null };
  }

  try {
    await loadGooglePlacesScript(apiKey);

    const country = await getGeolocation();

    return new Promise((resolve) => {
      const autocomplete = new window.google.maps.places.AutocompleteService();
      const sessionToken =
        new window.google.maps.places.AutocompleteSessionToken();

      autocomplete.getPlacePredictions(
        {
          input,
          types: ["address"],
          componentRestrictions: { country: country || "US" },
          sessionToken,
        },
        (predictions: GooglePrediction[] | null, status: string) => {
          if (status === "OK" && predictions) {
            const suggestions: AddressSuggestion[] = predictions.map(
              (prediction) => ({
                description: prediction.description,
                placeId: prediction.place_id,
                mainText: prediction.structured_formatting.main_text,
                secondaryText: prediction.structured_formatting.secondary_text,
              })
            );

            resolve({
              data: suggestions,
              error: null,
            });
          } else {
            resolve({
              data: null,
              error: status === "ZERO_RESULTS" ? null : `Error: ${status}`,
            });
          }
        }
      );
    });
  } catch (error) {
    console.error("Error fetching address suggestions:", error);
    return {
      data: null,
      error: error instanceof Error ? error.message : "An error occurred",
    };
  }
}
