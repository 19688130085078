import { FC } from "react";
import instagramIcon from "../../static/instagram.svg";
import twitterIcon from "../../static/twitter.svg";
import youtubeIcon from "../../static/youtube.svg";
import { observer } from "mobx-react-lite";
import whatsappIcon from "../../static/whatsapp-black.svg";

interface SocialMediaLinks {
  instagram?: string;
  youtube?: string;
  twitter?: string;
  whatsapp?: string;
}

interface SocialMediaSectionProps {
  socialMedia: SocialMediaLinks;
}

const SocialMediaSection: FC<SocialMediaSectionProps> = observer(
  ({ socialMedia }) => {
    return (
      <div className="tw-flex tw-items-center tw-mt-4 tw-space-x-4 tw-flex-wrap">
        {socialMedia?.instagram && (
          <a
            href={`https://instagram.com/${socialMedia.instagram}`}
            className="tw-flex tw-items-center tw-space-x-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={instagramIcon}
              alt="Instagram"
              className="tw-w-5 tw-h-5"
            />
            <span className="tw-hidden md:tw-inline">
              {socialMedia.instagram}
            </span>
          </a>
        )}

        {socialMedia?.youtube && (
          <a
            href={`https://youtube.com/${socialMedia.youtube}`}
            className="tw-flex tw-items-center tw-space-x-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={youtubeIcon} alt="Youtube" className="tw-w-5 tw-h-5" />
            <span className="tw-hidden md:tw-inline">
              {socialMedia.youtube}
            </span>
          </a>
        )}

        {socialMedia?.twitter && (
          <a
            href={`https://twitter.com/${socialMedia.twitter}`}
            className="tw-flex tw-items-center tw-space-x-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitterIcon} alt="Twitter" className="tw-w-5 tw-h-5" />
            <span className="tw-hidden md:tw-inline">
              {socialMedia.twitter}
            </span>
          </a>
        )}
        {socialMedia?.whatsapp && (
          <a
          href={`https://twitter.com/${socialMedia.twitter}`}
          className="tw-flex tw-items-center tw-space-x-2"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={whatsappIcon} className="tw-w-5 tw-h-5" alt="WhatsApp" />
        </a>
        )}
      </div>
    );
  }
);

export default SocialMediaSection;
