import { Button } from "../../shadcn-components/button";
import { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { ErrorBoundary } from "../error-boundary";
import { PictureUpload } from "../profile-picture";
import ProfileSettings from "./profile-settings";
import profileSettingsStore from "../../stores/profile-settings-store";
import { CustomDropdownWithIcon } from "../../shadcn-components/custom-dropdown-with-icon";
import { CustomTextareaWithIcon } from "../../shadcn-components/custom-textarea-with-icon";
import { Form, useForm } from "react-hook-form";
import {
  ProfileSettingsFormValues,
  profileSettingsSchema,
} from "../../stores/profile-settings.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import signUpStore from "../../stores/signup-store";
import { SquarePen } from "lucide-react";
import InterestsDialog from "./profile-edit-interests-dialog";
import { LoadingSpinner } from "../../shadcn-components/loading-spinner";
import { allInterests } from "../../utils/interests-utils";

export const ProfileEdit: FC = observer(() => {
  const { getEditProfileData, editProfileData, isSettingsOpen, isLoading } =
    profileSettingsStore;

  const { citiesOptions, interestOptions, getAllClientSideData: getAllOptionsForSignUp } =
    signUpStore;
  const [isInterestsModalOpen, setIsInterestsModalOpen] = useState(false);
  const form = useForm<ProfileSettingsFormValues>({
    resolver: zodResolver(profileSettingsSchema),
    defaultValues: {
      cities: "",
      bio: editProfileData?.bio,
      interests: [],
    },
  });

  useEffect(() => {
    const fetch = async () => {
      await getEditProfileData();
      await getAllOptionsForSignUp();
    };
    fetch();
  }, [getAllOptionsForSignUp, getEditProfileData]);

  useEffect(() => {
    if (editProfileData?.cities?.[0]) {
      form.setValue("cities", editProfileData.cities[0]);
    }
    if (editProfileData?.bio) {
      form.setValue("bio", editProfileData.bio);
    }
  }, [editProfileData, form, form.setValue]);

  const handleInterestsChange = async (newInterests: string[]) => {
    setIsInterestsModalOpen(false);
    profileSettingsStore.isLoading = true;
    await profileSettingsStore.updateField("interests", newInterests);
    await getEditProfileData();
  };

  if (isLoading) {
    return (
      <div className="tw-flex tw-flex-col tw-items-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div className="tw-min-h-screen tw-p-4">
      <div className="tw-max-w-[400px] tw-mx-auto">
        {isSettingsOpen ? (
          <ProfileSettings />
        ) : (
          <Form {...form}>
            <form className="tw-space-y-6">
              <div className="tw-flex tw-justify-between tw-items-center">
                <h2 className="tw-text-2xl tw-mb-4 tw-text-grey">
                  Profile Details
                </h2>
              </div>

              <div className="tw-mb-6">
                <PictureUpload
                  onImageUpload={() => {}}
                  currentImage={null}
                  size={88}
                />
              </div>

              <div>
                <div className="tw-flex tw-justify-between tw-items-center tw-mb-1">
                  <label className="tw-text-sm tw-font-medium tw-text-grey">
                    Location
                  </label>
                </div>
                <ErrorBoundary>
                  <CustomDropdownWithIcon
                    options={citiesOptions}
                    value={form.watch("cities") ?? ""}
                    onChange={async (value) => {
                      form.setValue("cities", value);
                      profileSettingsStore.updateField("cities", value);
                      await getEditProfileData();
                    }}
                    onSelect={() => {}}
                  />
                </ErrorBoundary>
                {form.formState.errors.cities && (
                  <p className="tw-text-red-500 tw-text-sm">
                    {form.formState.errors.cities.message}
                  </p>
                )}
              </div>

              <div className="tw-relative">
                <div className="tw-flex tw-justify-between tw-items-center tw-mb-1">
                  <label className="tw-text-sm tw-font-medium tw-text-grey">
                    Bio
                  </label>
                </div>
                <CustomTextareaWithIcon
                  value={form.watch("bio") ?? ""}
                  onChange={(e) => {
                    form.setValue("bio", e.target.value);
                  }}
                  onSave={(value) => {
                    profileSettingsStore.updateField("bio", value);
                  }}
                  className="tw-min-h-[100px] tw-w-full tw-p-2 tw-rounded-md"
                />
                {form.formState.errors.bio && (
                  <p className="tw-text-red-500 tw-text-sm">
                    {form.formState.errors.bio.message}
                  </p>
                )}
              </div>

              <div>
                <div className="tw-flex tw-justify-between tw-items-center tw-mb-1">
                  <label className="tw-text-sm tw-font-medium tw-text-grey">
                    Interests
                  </label>
                  <Button
                    variant="link"
                    size="icon"
                    onClick={() =>
                      setIsInterestsModalOpen(!isInterestsModalOpen)
                    }
                    type="button"
                    className="tw-p-0 hover:tw-bg-transparent"
                  >
                    <SquarePen className="tw-h-3 tw-w-3 tw-text-grey" />
                  </Button>
                </div>
                <div className="tw-flex tw-flex-wrap tw-gap-2">
                  {editProfileData?.interests.map((interest) => (
                    <Button
                      key={interest}
                      variant="outline"
                      className="tw-cursor-default tw-px-3 tw-py-1 tw-text-sm tw-rounded-full"
                      disabled={!isInterestsModalOpen}
                    >
                      {
                        interestOptions.find((c) => c.id === interest)
                          ?.display_en
                      }{" "}
                      {
                        allInterests.find(
                          (c) =>
                            c.name ===
                            interestOptions.find((c) => c.id === interest)
                              ?.display_en
                        )?.emoji
                      }
                    </Button>
                  ))}
                </div>
              </div>

              <InterestsDialog
                isOpen={isInterestsModalOpen}
                onOpenChange={() => {}}
                onConfirm={handleInterestsChange}
                selectedInterests={editProfileData?.interests ?? []}
              />
            </form>
          </Form>
        )}
      </div>
    </div>
  );
});
