import { observer } from "mobx-react-lite";
import { Card, CardContent } from "../../shadcn-components/card";
import { FC } from "react";
import { Community } from "../../stores/community-store";
import { VerifiedCheckMark } from "../verified-checkmark";
import { useNavigate } from "react-router-dom";

export const CommunityCardMobile: FC<{ community: Community }> = observer(
  ({ community }) => {
    const avatars = [
      "https://github.com/shadcn.png",
      "https://github.com/shadcn.png",
      "https://github.com/shadcn.png",
    ];
    const navigate = useNavigate();
    const onCardClick = () => {
      navigate(`/${community.handle}`);
    };
    return (
      <Card className="tw-w-full tw-max-w-md tw-border-custom-border" onClick={onCardClick}>
        <CardContent className="!tw-p-3">
          <div className="tw-flex tw-items-center tw-space-x-3">
            <div className="tw-h-8 tw-w-8 tw-rounded-xl tw-flex tw-items-center tw-justify-center">
              <img
                src={community.profileImageUrl}
                alt={community.title}
                className="tw-object-cover"
              />
            </div>

            <div className="tw-flex-1 tw-min-w-0">
              <div className="tw-flex tw-items-center tw-space-x-1 tw-text-gray-900">
                <h2 className="tw-text-base tw-font-semibold tw-truncate">
                  {community.title}
                </h2>
                <VerifiedCheckMark />
                <span className="tw-text-sm tw-text-gray-500 tw-truncate">
                  @{community.handle}
                </span>
              </div>
            </div>
          </div>

          <p className="tw-mt-3 tw-text-sm tw-text-gray-600 tw-leading-normal tw-text-left">
            {community.description}
          </p>

          <div className="tw-mt-4 tw-flex tw-items-center tw-space-x-2">
            <div className="tw-flex tw--space-x-2">
              {avatars.map((avatar, index) => (
                <div
                  key={index}
                  className="tw-h-6 tw-w-6 tw-rounded-full tw-border-2 tw-border-white tw-overflow-hidden"
                >
                  <img
                    src={avatar}
                    alt={`Follower ${index + 1}`}
                    className="tw-h-full tw-w-full tw-object-cover"
                  />
                </div>
              ))}
            </div>
            <span className="tw-text-sm tw-text-gray-500">
              {community.followerCount} Followers
            </span>
          </div>
        </CardContent>
      </Card>
    );
  }
);

export default CommunityCardMobile;
