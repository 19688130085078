import type { CountryCode } from "libphonenumber-js";

export async function getGeolocation(): Promise<CountryCode | null> {
  try {
    // Using a free IP geolocation API
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();

    return data.country as CountryCode;
  } catch (error) {
    console.error("Error fetching geolocation:", error);
    return null;
  }
}
