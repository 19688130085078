import React, { FC } from "react";
import { cn } from "../../lib/utils";
import { observer } from "mobx-react-lite";

interface AvatarCirclesProps {
  className?: string;
  numPeople?: number;
  avatarUrls: string[];
}

export const AvatarCircles: FC<AvatarCirclesProps> = observer(
  ({ numPeople, className, avatarUrls }) => {
    return (
      <div
        className={cn(
          "tw-z-10 tw-flex tw--space-x-4 tw-rtl:space-x-reverse",
          className
        )}
      >
        {avatarUrls.map((url, index) => (
          <img
            key={index}
            className="tw-size-10 tw-rounded-full tw-border-2 tw-border-white dark:tw-border-gray-800"
            src={url}
            width={40}
            height={40}
            alt={`Avatar ${index + 1}`}
          />
        ))}
        {/* <a
          className="tw-flex tw-size-10 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-white tw-bg-black tw-text-center tw-text-xs tw-font-medium tw-text-white hover:tw-bg-gray-600 dark:tw-border-gray-800 dark:tw-bg-white dark:tw-text-black"
          href=""
        >
          +{numPeople}
        </a> */}
      </div>
    );
  }
);
