import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { CommunityCard } from "./community-card";
import { Community, communityStore } from "../../stores/community-store";
import bhagClubProfile from "../../static/bhag-profile-pic.png";
import bhagClubBanner from "../../static/bhag-banner.jpg";
import { LoadingSpinner } from "../../shadcn-components/loading-spinner";
import { Button } from "../../shadcn-components/button";
import { useNavigate } from "react-router-dom";
import { Plus } from "lucide-react";
import CommunityCardMobile from "./community-card-mobile";

export const CommunitiesHome: FC = observer(() => {
  const {
    adminCommunities,
    followingCommunities,
    isAdminCommunitiesLoading,
    isFollowingCommunitiesLoading,
  } = communityStore;
  useEffect(() => {
    const fetch = async () => {};
    communityStore.getAdminCommunities();
    communityStore.getFollowingCommunities();
    fetch();
  }, []);
  const navigate = useNavigate();

  adminCommunities.forEach((c) => {
    c.bannerImageUrls = bhagClubBanner;
    c.profileImageUrl = bhagClubProfile;
  });
  followingCommunities.forEach((c) => {
    c.bannerImageUrls = bhagClubBanner;
    c.profileImageUrl = bhagClubProfile;
  });

  const onStartCommunityClick = () => {
    navigate("/c/new");
  };
  const CommunityCardResponsive = ({ community }: { community: Community }) => {
    return (
      <>
        {/* Mobile Card - Show on small screens */}
        <div className="md:tw-hidden">
          <CommunityCardMobile community={community} />
        </div>
        {/* Desktop Card - Show on medium screens and up */}
        <div className="tw-hidden md:tw-block">
          <CommunityCard community={community} />
        </div>
      </>
    );
  };

  return (
    <div className="tw-px-4 md:tw-px-12">
      <div className="tw-mt-6">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <h2 className="tw-text-xl tw-font-semibold">My communities</h2>
          <Button
            variant="default"
            size={window.innerWidth < 768 ? "icon" : "default"}
            className="md:tw-px-4 md:tw-font-normal !tw-rounded-full tw-bg-primary tw-text-white hover:tw-bg-blue-700"
            onClick={onStartCommunityClick}
          >
            <Plus className="tw-h-4 tw-w-4 md:tw-hidden" />
            <span className="tw-hidden md:tw-inline">Start a Community</span>
          </Button>
        </div>
        <div className="tw-relative">
          <style>
            {`
              .custom-scrollbar::-webkit-scrollbar {
                height: 8px;
              }
              .custom-scrollbar::-webkit-scrollbar-track {
                background: rgba(30, 40, 255, 0.1);
                border-radius: 9999px;
              }
              .custom-scrollbar::-webkit-scrollbar-thumb {
                background: #1E28FF;
                border-radius: 9999px;
              }
              .custom-scrollbar::-webkit-scrollbar-button {
                display: none;
              }
            `}
          </style>
          {isAdminCommunitiesLoading ? (
            <div className="tw-flex tw-justify-center tw-py-8">
              <LoadingSpinner />
            </div>
          ) : adminCommunities.length > 0 ? (
            <div
              className="
                custom-scrollbar
                tw-overflow-x-auto 
                tw-overflow-y-hidden
                tw-flex 
                tw-space-x-4 
                md:tw-space-x-6 
                tw-pb-4 
                tw-relative
              "
            >
              {adminCommunities.map((community) => (
                <div
                  key={community.id}
                  className="tw-flex-none tw-w-full md:tw-w-80"
                >
                  <CommunityCardResponsive community={community} />
                </div>
              ))}
            </div>
          ) : (
            <div className="tw-text-center tw-py-8 tw-bg-gray-50 tw-rounded-lg">
              <p className="tw-text-gray-500">
                You haven't created or joined any communities as an admin yet.
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="tw-mt-8 md:tw-mt-12">
        <h2 className="tw-text-xl tw-font-semibold tw-mb-4 tw-text-left">
          Following communities
        </h2>
        <div className="tw-relative">
          {isFollowingCommunitiesLoading ? (
            <div className="tw-flex tw-justify-center tw-py-8">
              <LoadingSpinner />
            </div>
          ) : followingCommunities.length > 0 ? (
            <div
              className="
                custom-scrollbar
                tw-overflow-x-auto 
                tw-overflow-y-hidden
                tw-flex 
                tw-space-x-4 
                md:tw-space-x-6 
                tw-pb-4 
                tw-relative
              "
            >
              {followingCommunities.map((community) => (
                <div
                  key={community.id}
                  className="tw-flex-none tw-w-full md:tw-w-80"
                >
                  <CommunityCardResponsive community={community} />
                </div>
              ))}
            </div>
          ) : (
            <div className="tw-text-center tw-py-8 tw-bg-gray-50 tw-rounded-lg">
              <p className="tw-text-gray-500">
                You're not following any communities yet.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
