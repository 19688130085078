import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { Card } from "../../shadcn-components/card";
import { Input } from "../../shadcn-components/input";
import { Button } from "../../shadcn-components/button";
import { Label } from "../../shadcn-components/label";

export const ProfileForm: FC = observer(() => {
  const [profileImage, setProfileImage] = useState<string>("S");
  const [selectedGender, setSelectedGender] = useState<string>("");

  return (
    <div className="tw-min-h-screen tw-p-4">
      <h2 className="tw-text-xl tw-font-semibold tw-mb-6">
        Let us get to know you
      </h2>

      {/* Profile Photo Circle */}
      <div className="tw-flex tw-justify-center tw-mb-6">
        <div className="tw-relative">
          <div className="tw-w-16 tw-h-16 tw-rounded-full tw-bg-blue-500 tw-flex tw-items-center tw-justify-center">
            <span className="tw-text-white tw-text-2xl">{profileImage}</span>
          </div>
          <Button
            variant="outline"
            className="tw-mt-2 tw-w-full tw-text-[#1E28FF] !tw-border-[#1E28FF] !tw-rounded-[38px]"
            onClick={() => {}}
          >
            Add Profile Photo
          </Button>
        </div>
      </div>

      {/* Form Fields */}
      <div className="tw-space-y-6">
        <div className="tw-space-y-2">
          <Label htmlFor="name" className="tw-block">
            Name <span className="tw-text-red-500">*</span>
          </Label>
          <Input id="name" placeholder="" className="tw-w-full" required />
        </div>

        <div className="tw-space-y-2">
          <Label htmlFor="email" className="tw-block">
            Email
          </Label>
          <Input id="email" type="email" placeholder="" className="tw-w-full" />
        </div>

        <div className="tw-space-y-2">
          <Label className="tw-block">
            Gender <span className="tw-text-red-500">*</span>
          </Label>
          <div className="tw-flex tw-gap-2">
            {["Woman", "Man", "Others"].map((gender) => (
              <Button
                key={gender}
                variant="outline"
                className={`tw-flex-1 !tw-rounded-[38px] ${
                  selectedGender === gender
                    ? "tw-border-[#1E28FF] tw-bg-[#1E28FF]/0.1 tw-bg-[] tw-border-1 tw-text-[#1E28FF]"
                    : "tw-border-[#131315]/[0.1] tw-border-1"
                }`}
                onClick={() => setSelectedGender(gender)}
              >
                {gender}
              </Button>
            ))}
          </div>
        </div>
      </div>

      {/* Next Button */}
      <Button className="tw-w-full tw-mt-8 tw-bg-violet-500 tw-hover:bg-violet-600">
        Next
      </Button>
    </div>
  );
});
