import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { Button } from "../../shadcn-components/button";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../shadcn-components/tabs";
import { EventData } from "../../components/events/stores/event-store";
import { ChevronLeft } from "lucide-react";
import { exploreStore } from "../../stores/explore-store";
import { Community } from "../../stores/community-store";
import { Card, CardContent, CardHeader } from "../../shadcn-components/card";
import { useNavigate } from "react-router-dom";

const EventCard: FC<EventData> = (event) => {
  const navigate = useNavigate();
  return (
    <div
      className="tw-rounded-xl tw-overflow-hidden tw-bg-white"
      onClick={() => navigate(`/e/${event.eventId}`)}
    >
      <div className="tw-aspect-[1.91/1] tw-relative tw-mb-2">
        <img
          src={
            event.logo
              ? `https://jackpass-backend-staging-images-bucket.s3.ap-south-1.amazonaws.com/event-images_200x200/${event.logo}`
              : ""
          }
          alt={event.name}
          className="tw-w-full tw-h-full tw-object-cover tw-rounded-2xl"
        />
      </div>
      <div>
        <div className="tw-flex tw-items-center tw-gap-2 tw-mb-1">
          <img
            src="/api/placeholder/24/24"
            alt="Community"
            className="tw-w-6 tw-h-6 tw-rounded-full"
          />
          <span className="tw-text-xs tw-text-gray-600">
            By {event.communityDetails.title}
          </span>
        </div>
        <h3 className="tw-text-base tw-font-semibold tw-mb-3 tw-text-left">
          {event.name}
        </h3>
        <div className="tw-flex tw-flex-col tw-gap-1 tw-text-gray-600">
          <div className="tw-flex tw-items-center tw-gap-2">
            <span className="tw-text-sm">🕐</span>
            <span className="tw-text-sm">
              {new Date(event.startDateTime).toLocaleString("en-US", {
                weekday: "long",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </span>
          </div>
          <div className="tw-flex tw-items-center tw-gap-2">
            <span className="tw-text-sm tw-ml-1">📍</span>
            <span className="tw-text-sm">{event.location}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommunityCard: FC<Community> = (community) => (
  <Card className="tw-overflow-hidden tw-w-full tw-mb-4 tw-border-0 tw-rounded-none tw-bg-none tw-shadow-none">
    <CardHeader className="!tw-p-0">
      <div className="tw-h-[171px] tw-relative">
        <img
          src={
            community.bannerImageUrls
              ? `https://jackpass-backend-staging-images-bucket.s3.ap-south-1.amazonaws.com/community-banner-images_300x300/${community.bannerImageUrls}`
              : ""
          }
          alt={community.title}
          className="tw-w-full tw-h-full tw-object-cover tw-rounded-lg"
        />
      </div>
    </CardHeader>
    <CardContent className="tw-flex tw-flex-col !tw-pt-2 tw-px-0 tw-pb-0">
      <div className="tw-flex tw-items-center tw-justify-between">
        <h3 className="tw-text-base tw-font-bold">{community.title}</h3>
        <div className="tw-flex tw-items-center tw-gap-1">
          <div className="tw-flex -tw-space-x-2">
            {[1, 2, 3].map((i) => (
              <div
                key={i}
                className="tw-w-6 tw-h-6 tw-rounded-full tw-border-2 tw-border-white tw-bg-gray-200"
              >
                <img
                  src={
                    community.profileImageUrl
                      ? `https://jackpass-backend-staging-images-bucket.s3.ap-south-1.amazonaws.com/community-logo-images_100x100/${community.profileImageUrl}`
                      : ""
                  }
                  alt=""
                  className="tw-w-full tw-h-full tw-rounded-full tw-object-cover"
                />
              </div>
            ))}
          </div>
          <span className="tw-text-sm tw-text-grey tw-font-semibold">
            {community.followerCount} Members
          </span>
        </div>
      </div>
      <p className="tw-text-sm tw-text-gray-600 tw-mt-2 tw-text-left tw-line-clamp-2">
        {community.description}
      </p>
      <span className="tw-text-sm tw-font-semibold tw-text-left tw-mt-2">
        @{community.handle}
      </span>
    </CardContent>
  </Card>
);

export const ExploreContent: FC = observer(() => {
  const { exploreEvents, getEvents, exploreCommunities, getCommunities } =
    exploreStore;
  useEffect(() => {
    getEvents();
    getCommunities();
  }, [getCommunities, getEvents]);
  const tabTriggerStyles =
    "tw-px-4 tw-py-2 data-[state=active]:tw-bg-transparent data-[state=active]:tw-shadow-none tw-relative tw-h-auto \
  data-[state=active]:tw-text-[#1E28FF] tw-text-gray-500 tw-rounded-none tw-flex-1 tw-max-w-[200px] \
  before:tw-content-[''] before:tw-absolute before:tw-bottom-[-1px] before:tw-left-0 before:tw-right-0 \
  before:tw-h-[1px] before:tw-bg-gray-200 \
  after:tw-content-[''] after:tw-absolute after:tw-bottom-[-1px] after:tw-left-0 after:tw-right-0 \
  after:tw-h-[2px] after:tw-bg-[#1E28FF] after:tw-transform after:tw-scale-x-0 \
  data-[state=active]:after:tw-scale-x-100 after:tw-transition-transform \
  data-[state=active]:before:tw-opacity-0";

  return (
    <div className="tw-max-w-2xl tw-mx-auto tw-px-4">
      <Tabs
        defaultValue="events"
        className="tw-w-full"
        onValueChange={(value) => {
          exploreStore.selectedTabValue = value;
        }}
        value={exploreStore.selectedTabValue}
      >
        <TabsList className="tw-h-auto tw-p-0 tw-bg-transparent tw-border-gray-200 tw-w-full tw-justify-start">
          <TabsTrigger value="events" className={tabTriggerStyles}>
            Events
          </TabsTrigger>
          <TabsTrigger value="communities" className={tabTriggerStyles}>
            Communities
          </TabsTrigger>
        </TabsList>

        <TabsContent value="events">
          <div className="tw-space-y-2">
            {exploreEvents.map((event) => (
              <EventCard key={event.eventId} {...event} />
            ))}
          </div>
        </TabsContent>

        <TabsContent value="communities">
          <div className="tw-grid tw-grid-cols-1 tw-gap-2">
            {exploreCommunities.map((c) => (
              <CommunityCard key={c.id} {...c} />
            ))}
          </div>
          <div className="tw-h-2 tw-bg-gray-100 tw-w-full tw-mb-6" />

          <div className="tw-text-center tw-py-8">
            <p className="tw-text-base tw-mb-2">Din't find any communities?</p>
            <a href="#" className="tw-text-[#1E28FF] tw-font-medium">
              Create One
            </a>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
});
