import { useEffect, useRef } from "react";
import { Button } from "../../../shadcn-components/button";
import { observer } from "mobx-react-lite";
import EditableInput from "../../editable-input";
import { CustomTextareaWithIcon } from "../../../shadcn-components/custom-textarea-with-icon";
import { useParams } from "react-router-dom";
import { manageEventStore } from "../stores/manage-event.store";
import { LoadingSpinner } from "../../../shadcn-components/loading-spinner";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { DateTimePicker } from "../../../shadcn-components/datetime-picker";
import { ImageIcon, Upload } from "lucide-react";
import { CommunitySelect } from "../../community-select";
import NumberEditableInput from "../../number-editable-input";
import { normalizeDateToMinute } from "../../../utils/datetime-utils";

const manageEventSchema = z.object({
  communityId: z.string({
    required_error: "Please select a community",
  }),
  name: z.string().min(1, "Event name is required"),
  startDateTime: z.string().min(1, "Start date is required"),
  endDateTime: z.string().min(1, "End date is required"),
  description: z.string().min(10, "Description must be at least 10 characters"),
  location: z.string().min(1, "Location is required"),
  city: z.string().min(1, "City is required"),
  totalCapacity: z.string().min(1, "Capacity is required"),
});

type ManageEventFormValues = z.infer<typeof manageEventSchema>;

const ManageEventForm = observer(() => {
  const { eventId } = useParams<{ eventId: string }>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    manageEventStore.getAdminCommunities();
  }, []);
  const {
    editEventData,
    isLoading,
    updateStatus,
    getEventData,
    updateField,
    // updateImage,
    isSubmitting,
  } = manageEventStore;

  const {
    setValue,
    watch,
    formState: { errors },
  } = useForm<ManageEventFormValues>({
    resolver: zodResolver(manageEventSchema),
    defaultValues: {
      communityId: "",
      name: "",
      startDateTime: normalizeDateToMinute(new Date()).toISOString(),
      endDateTime: normalizeDateToMinute(
        new Date(Date.now() + 3600000)
      ).toISOString(),
      description: "",
      location: "",
      city: "",
      totalCapacity: "",
    },
  });

  useEffect(() => {
    if (eventId) {
      getEventData(eventId);
    }
  }, [eventId, getEventData]);

  useEffect(() => {
    if (editEventData) {
      setValue("communityId", editEventData.communityId);
      setValue("name", editEventData.name);
      setValue("description", editEventData.description);
      setValue("location", editEventData.location);
      setValue("city", editEventData.city);
      setValue("totalCapacity", editEventData.totalCapacity?.toString() || "");

      if (editEventData.startDateTime) {
        setValue("startDateTime", editEventData.startDateTime);
      }
      if (editEventData.endDateTime) {
        setValue("endDateTime", editEventData.endDateTime);
      }
    }
  }, [editEventData, setValue]);

  const handleFieldUpdate = async (
    field: keyof ManageEventFormValues,
    value: any
  ) => {
    try {
      await updateField(field, value);
      manageEventStore.clearUpdateStatus();

      if (eventId) {
        await getEventData(eventId);
      }
    } catch (error) {
      console.error("Field update error:", error);
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // updateImage(file);
    }
  };

  const triggerImageUpload = () => {
    fileInputRef.current?.click();
  };

  if (isLoading) {
    return (
      <div className="tw-flex tw-flex-col tw-items-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="tw-mx-auto tw-p-12">
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-6">
        <div className="tw-w-full md:tw-w-[300px] tw-shrink-0">
          <div className="tw-h-[200px] tw-bg-gray-200 tw-rounded-lg tw-flex tw-items-center tw-justify-center tw-overflow-hidden">
            {/* {editEventData?.image ? (
              <img
                src={editEventData.image}
                alt="Event Banner"
                className="tw-w-full tw-h-full tw-object-cover"
              />
            ) : (
              <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-2">
                <ImageIcon size={32} className="tw-text-gray-400" />
                <span className="tw-text-sm tw-text-gray-500">
                  No image uploaded
                </span>
              </div>
            )} */}
          </div>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            className="tw-hidden"
            onChange={handleImageUpload}
          />
          <Button
            type="button"
            variant="outline"
            size="sm"
            className="tw-w-full tw-mt-2 tw-flex tw-items-center tw-justify-center tw-space-x-2 !tw-border-custom-border"
            onClick={triggerImageUpload}
          >
            <Upload size={14} />
            <span>
              {/* {editEventData?.image ? "Change Image" : "Upload Image"} */}
              {"Change Image"}
            </span>
          </Button>
        </div>

        <div className="tw-flex-1 tw-space-y-4">
          <CommunitySelect
            value={watch("communityId")}
            onValueChange={(value) => {
              setValue("communityId", value, {
                shouldValidate: true,
                shouldDirty: true,
              });
              handleFieldUpdate("communityId", value);
            }}
            onCommunitySelect={(community) => {
              if (community.location) {
                setValue("city", community.location, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
                handleFieldUpdate("city", community.location);
              }
            }}
            error={errors.communityId?.message}
            disabled={isSubmitting}
            isLoading={manageEventStore.isLoadingCommunities}
            communities={manageEventStore.adminCommunities}
          />

          <EditableInput
            label="Event Name"
            value={watch("name")}
            onSave={(value) => handleFieldUpdate("name", value)}
            status={updateStatus?.field === "name" ? updateStatus : null}
            name="name"
          />

          <div>
            <label className="tw-block tw-text-sm tw-font-medium tw-text-grey tw-mb-1">
              Start Date
            </label>
            <DateTimePicker
              value={new Date(watch("startDateTime"))}
              onChange={(date) => {
                if (date) {
                  const normalizedDate = normalizeDateToMinute(date);
                  setValue("startDateTime", normalizedDate.toISOString());
                  handleFieldUpdate(
                    "startDateTime",
                    normalizedDate.toISOString()
                  );
                }
              }}
              use12HourFormat
              timePicker={{ hour: true, minute: true }}
            />
            {errors.startDateTime && (
              <p className="tw-text-sm tw-text-red-500 tw-mt-1">
                {errors.startDateTime.message}
              </p>
            )}
          </div>

          <div>
            <label className="tw-block tw-text-sm tw-font-medium tw-text-grey tw-mb-1">
              End Date
            </label>
            <DateTimePicker
              value={new Date(watch("endDateTime"))}
              onChange={(date) => {
                if (date) {
                  const normalizedDate = normalizeDateToMinute(date);
                  setValue("endDateTime", normalizedDate.toISOString());
                  handleFieldUpdate(
                    "endDateTime",
                    normalizedDate.toISOString()
                  );
                }
              }}
              use12HourFormat
              timePicker={{ hour: true, minute: true }}
            />
            {errors.endDateTime && (
              <p className="tw-text-sm tw-text-red-500 tw-mt-1">
                {errors.endDateTime.message}
              </p>
            )}
          </div>

          <EditableInput
            label="Location"
            value={watch("location")}
            onSave={(value) => handleFieldUpdate("location", value)}
            status={updateStatus?.field === "location" ? updateStatus : null}
            name="location"
          />

          <div>
            <label className="tw-block tw-text-left tw-text-sm tw-font-medium tw-text-grey">
              Description
            </label>
            <CustomTextareaWithIcon
              value={watch("description")}
              onChange={(e) => setValue("description", e.target.value)}
              onSave={(value) => handleFieldUpdate("description", value)}
              className="tw-min-h-[100px]"
            />
            {updateStatus?.field === "description" && (
              <p
                className={`tw-text-sm tw-mt-1 ${
                  updateStatus.status === "error"
                    ? "tw-text-red-500"
                    : updateStatus.status === "success"
                    ? "tw-text-green-500"
                    : "tw-text-blue-500"
                }`}
              >
                {updateStatus.message}
              </p>
            )}
          </div>

          <div className="tw-flex tw-items-center tw-justify-between">
            <label className="tw-text-sm tw-font-medium tw-text-grey">
              Capacity
            </label>
            <div className="tw-w-24">
              <NumberEditableInput
                value={watch("totalCapacity") || ""}
                onSave={async (value) => {
                  await handleFieldUpdate("totalCapacity", value);
                }}
                status={
                  updateStatus?.field === "totalCapacity" ? updateStatus : null
                }
                name="totalCapacity"
                label=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ManageEventForm;
