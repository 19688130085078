import { useLocation, useNavigate } from "react-router-dom";
import { Home, Search, Calendar, User } from "lucide-react";

const BottomNav = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const navItems = [
      { icon: Home, label: 'Home', path: '/' },
      { icon: Search, label: 'Explore', path: '/explore' },
      { icon: Calendar, label: 'Calendar', path: '/calendar' },
      { icon: User, label: 'Me', path: '/me' }
    ];
  
    return (
      <nav className="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-bg-white tw-border-t tw-border-gray-200 tw-flex tw-justify-around tw-py-2 md:tw-hidden">
        {navItems.map(({ icon: Icon, label, path }) => (
          <button
            key={label}
            onClick={() => navigate(path)}
            className={`tw-flex tw-flex-col tw-items-center tw-px-3 tw-py-1 ${
              location.pathname === path ? 'tw-text-blue-600' : 'tw-text-gray-600'
            }`}
          >
            <Icon size={24} />
            <span className="tw-text-xs tw-mt-1">{label}</span>
          </button>
        ))}
      </nav>
    );
  };
  
  export default BottomNav;