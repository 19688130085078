import parsePhoneNumberFromString, {
  AsYouType,
  type CarrierCode,
  type CountryCallingCode,
  type E164Number,
  type NationalNumber,
  type CountryCode,
  type NumberType,
} from "libphonenumber-js";
import { Check, ChevronsUpDown } from "lucide-react";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useStateHistory } from "../utils/use-state-history";

import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Button } from "./button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./command";
import { ScrollArea } from "./scroll-area";
import { Input } from "./input";
import { cn } from "../lib/utils";
import { countries } from "../utils/countries";

export type Country = (typeof countries)[number];

export type PhoneData = {
  phoneNumber?: E164Number;
  countryCode?: CountryCode;
  countryCallingCode?: CountryCallingCode;
  carrierCode?: CarrierCode;
  nationalNumber?: NationalNumber;
  internationalNumber?: string;
  possibleCountries?: string;
  isValid?: boolean;
  isPossible?: boolean;
  uri?: string;
  type?: NumberType;
};

interface PhoneInputProps extends React.ComponentPropsWithoutRef<"input"> {
  value?: string;
  defaultCountry?: CountryCode;
}
// https://github.com/damianricobelli/shadcn-phone-input/tree/master
export function getPhoneData(phone: string): PhoneData {
  const asYouType = new AsYouType();
  asYouType.input(phone);
  const number = asYouType.getNumber();
  return {
    phoneNumber: number?.number,
    countryCode: number?.country,
    countryCallingCode: number?.countryCallingCode,
    carrierCode: number?.carrierCode,
    nationalNumber: number?.nationalNumber,
    internationalNumber: number?.formatInternational(),
    possibleCountries: number?.getPossibleCountries().join(", "),
    isValid: number?.isValid(),
    isPossible: number?.isPossible(),
    uri: number?.getURI(),
    type: number?.getType(),
  };
}

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  (
    {
      value: valueProp,
      defaultCountry = "IN",
      className,
      id,
      required = true,
      ...rest
    },
    ref
  ) => {
    const asYouType = new AsYouType();
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => inputRef.current!);

    const [value, handlers, history] = useStateHistory(valueProp);

    if (value && value.length > 0) {
      defaultCountry =
        parsePhoneNumberFromString(value)?.getPossibleCountries()[0] ||
        defaultCountry;
    }

    const [openCommand, setOpenCommand] = useState(false);
    const [countryCode, setCountryCode] = useState<CountryCode>(defaultCountry);
    useEffect(() => {
      if (value && value.length > 0) {
        const parsedNumber = parsePhoneNumberFromString(value);
        if (parsedNumber) {
          setCountryCode(parsedNumber.country || defaultCountry);
        }
      }
    }, [value, defaultCountry]);

    const getCountryCodePrefix = () => {
      return `+${selectedCountry?.phone_code || ""}`;
    };

    const selectedCountry = countries.find(
      (country) => country.iso2 === countryCode
    );

    const sanitizeInput = (input: string): string => {
      const prefix = getCountryCodePrefix();
      // Remove all non-digit characters except the plus sign at the start
      const sanitized = input.replace(/[^\d+]/g, "");
      // Ensure the prefix is maintained
      if (!sanitized.startsWith(prefix)) {
        return prefix + sanitized.replace(/^\+/, "");
      }
      return sanitized;
    };

    const handleOnInput = (event: React.FormEvent<HTMLInputElement>) => {
      asYouType.reset();

      let inputValue = sanitizeInput(event.currentTarget.value);
      const prefix = getCountryCodePrefix();

      if (!inputValue.startsWith(prefix)) {
        inputValue = prefix + inputValue.replace(/^\+/, "");
      }

      let formattedValue;
      if (countryCode === "IN") {
        // For India, don't format the number
        formattedValue = inputValue;
      } else {
        formattedValue = asYouType.input(inputValue);
      }

      const number = asYouType.getNumber();

      if (number?.country) {
        setCountryCode(number.country);
      }

      event.currentTarget.value = formattedValue;
      handlers.set(formattedValue);
    };

    const handleOnPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      asYouType.reset();

      const clipboardData = event.clipboardData;

      if (clipboardData) {
        let pastedData = sanitizeInput(clipboardData.getData("text/plain"));
        const prefix = getCountryCodePrefix();

        // Ensure the prefix is always present
        if (!pastedData.startsWith(prefix)) {
          pastedData = prefix + pastedData.replace(/^\+/, "");
        }

        const formattedValue = asYouType.input(pastedData);
        const number = asYouType.getNumber();

        if (number?.country) {
          setCountryCode(number.country);
        }

        event.currentTarget.value = formattedValue;
        handlers.set(formattedValue);
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      const prefix = getCountryCodePrefix();
      const currentValue = event.currentTarget.value;

      // Prevent deleting the country code
      if (event.key === "Backspace" && currentValue === prefix) {
        event.preventDefault();
        return;
      }

      // Prevent typing non-digit keys (except for allowed control keys)
      const allowedKeys = [
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Tab",
        "Home",
        "End",
      ];

      if (
        !allowedKeys.includes(event.key) &&
        !(event.ctrlKey || event.metaKey) &&
        !/^\d$/.test(event.key)
      ) {
        event.preventDefault();
        return;
      }

      if ((event.metaKey || event.ctrlKey) && event.key === "z") {
        handlers.back();
        if (
          inputRef.current &&
          history.current > 0 &&
          history.history[history.current - 1] !== undefined
        ) {
          event.preventDefault();
          const historicalValue = history.history[history.current - 1] || "";
          inputRef.current.value = historicalValue.startsWith(prefix)
            ? historicalValue
            : prefix + historicalValue.replace(/^\+/, "");
        }
      }
    };

    return (
      <div className={cn("tw-flex tw-gap-2", className)}>
        <Popover open={openCommand} onOpenChange={setOpenCommand} modal={true}>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              role="combobox"
              aria-expanded={openCommand}
              className="tw-w-max tw-items-center tw-justify-between tw-whitespace-nowrap !tw-border-custom-border"
            >
              {selectedCountry?.name ? (
                <span className="tw-relative tw-top-0.5">
                  {selectedCountry.name}
                </span>
              ) : (
                "Select country"
              )}
              <ChevronsUpDown className="tw-ml-2 tw-size-4 tw-shrink-0 tw-opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="tw-p-0 tw-w-max" align="start">
            <Command>
              <CommandInput placeholder="Search country..." />
              <CommandList>
                <CommandEmpty>No country found.</CommandEmpty>
                <ScrollArea
                  className={
                    "[&>[data-radix-scroll-area-viewport]]:tw-max-h-[300px]"
                  }
                >
                  <CommandGroup>
                    {countries.map((country) => {
                      return (
                        <CommandItem
                          key={country.iso3}
                          value={`${country.name} (+${country.phone_code})`}
                          onSelect={() => {
                            if (inputRef.current) {
                              inputRef.current.value = `+${country.phone_code}`;
                              handlers.set(`+${country.phone_code}`);
                              inputRef.current.focus();
                            }
                            setCountryCode(country.iso2 as CountryCode);
                            setOpenCommand(false);
                          }}
                        >
                          <Check
                            className={cn(
                              "tw-mr-2 tw-size-4",
                              countryCode === country.iso2
                                ? "tw-opacity-100"
                                : "tw-opacity-0"
                            )}
                          />
                          {country.name}
                          <span className="tw-text-gray-11 tw-ml-1">
                            (+{country.phone_code})
                          </span>
                        </CommandItem>
                      );
                    })}
                  </CommandGroup>
                </ScrollArea>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
        <Input
          ref={inputRef}
          type="tel"
          pattern="^\+[0-9]+$"
          name="phone"
          id={id}
          placeholder="Phone"
          defaultValue={getCountryCodePrefix()}
          onInput={handleOnInput}
          onPaste={handleOnPaste}
          onKeyDown={handleKeyDown}
          required={required}
          aria-required={required}
          {...rest}
        />
      </div>
    );
  }
);
