import axios from "axios";
import { action, makeAutoObservable, observable, runInAction } from "mobx";
import authStore from "../../../stores/auth-store";
import { EventData } from "./event-store";
import { Community } from "@/components/communities/api/community-api";
import { toast } from "@/hooks/use-toast";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

interface UpdateStatus {
  field: string;
  status: "saving" | "success" | "error";
  message?: string;
}

class ManageEventStore {
  @observable editEventData: EventData | null = null;
  @observable isLoading = false;
  @observable updateStatus: UpdateStatus | null = null;
  @observable isSubmitting = false;
  @observable adminCommunities: Community[] = [];
  @observable isLoadingCommunities = false;


  constructor() {
    makeAutoObservable(this);
  }

  @action
  getEventData = async (eventId: string) => {
    this.isLoading = true;
    try {
      const response = await axios.get(`${apiBaseUrl}/events/${eventId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authStore.accessToken}`,
        },
      });
      runInAction(() => {
        this.editEventData = response.data.data;
      });
    } catch (error) {
      console.error("Error fetching event data:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  updateField = async (field: string, value: any) => {
    try {
      runInAction(() => {
        this.updateStatus = { field, status: "saving" };
      });

      if (!this.editEventData?.eventId) {
        runInAction(() => {
          this.updateStatus = {
            field,
            status: "error",
            message: "Event data not found",
          };
        });
        return;
      }

      const updateData = { [field]: value };

      const response = await axios.patch(
        `${apiBaseUrl}/events/${this.editEventData.eventId}`,
        updateData,
        {
          headers: {
            Authorization: `${authStore.accessToken}`,
          },
        }
      );

      runInAction(() => {
        if (response.data.status === "success") {
          this.editEventData = {
            ...this.editEventData!,
            ...response.data.updatedEvent,
          };

          this.updateStatus = {
            field,
            status: "success",
            message: "Successfully updated",
          };
        }
      });
    } catch (error: any) {
      runInAction(() => {
        this.updateStatus = {
          field,
          status: "error",
          message: error.response?.data?.message || "Update failed",
        };
      });
    }
  };

  @action
  getAdminCommunities = async () => {
    try {
      this.isLoadingCommunities = true;
      const response = await axios.get(
        `${apiBaseUrl}/communities?isAdmin=true`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authStore.accessToken}`,
          },
        }
      );

      runInAction(() => {
        this.adminCommunities = response.data.items;
      });
    } catch (error) {
      console.error("Failed to fetch communities:", error);
      runInAction(() => {
        this.adminCommunities = [];
      });
    } finally {
      runInAction(() => {
        this.isLoadingCommunities = false;
      });
    }
  };


  // @action
  // updateImage = async (imageFile: File) => {
  //   try {
  //     runInAction(() => {
  //       this.updateStatus = { field: "image", status: "saving" };
  //     });

  //     const formData = new FormData();
  //     formData.append("image", imageFile);

  //     const response = await axios.post(
  //       `${apiBaseUrl}/events/${this.editEventData?.eventId}/image`,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: `${authStore.accessToken}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );

  //     runInAction(() => {
  //       if (response.data.status === "success") {
  //         this.editEventData = {
  //           ...this.editEventData!,
  //           image: response.data.imageUrl,
  //         };

  //         this.updateStatus = {
  //           field: "image",
  //           status: "success",
  //           message: "Image updated successfully",
  //         };
  //       }
  //     });
  //   } catch (error: any) {
  //     runInAction(() => {
  //       this.updateStatus = {
  //         field: "image",
  //         status: "error",
  //         message: error.response?.data?.message || "Failed to update image",
  //       };
  //     });
  //   } finally {
  //     setTimeout(() => {
  //       runInAction(() => {
  //         this.updateStatus = null;
  //       });
  //     }, 2000);
  //   }
  // };

  @action
  clearUpdateStatus = () => {
    this.updateStatus = null;
  };
}

export const manageEventStore = new ManageEventStore();
