import { SquarePen } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./dropdown-menu";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { Button } from "./button";

interface OptionItem {
  display_en: string;
  id: string;
}

interface CustomDropdownWithIconProps {
  options: OptionItem[];
  value: string;
  onChange: (value: string) => void;
  onSelect: () => void;
}

export const CustomDropdownWithIcon: FC<CustomDropdownWithIconProps> = ({
  options,
  value,
  onChange,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (optionId: string) => {
    onChange(optionId);
    onSelect();
    setIsOpen(false);
  };

  const getCurrentDisplayName = () => {
    const selectedOption = options.find((option) => option.id === value);
    return selectedOption?.display_en || "";
  };

  return (
    <div className="tw-relative">
      <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            variant="outline"
            className="tw-w-full tw-pr-10 tw-justify-between !tw-border-custom-border  tw-mt-0"
            disabled={!isOpen}
          >
            {getCurrentDisplayName()}
          </Button>
        </DropdownMenuTrigger>
        <Button
          className="tw-absolute tw-right-1 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-p-0"
          variant="link"
          size="icon"
          type="button"
          onClick={() => setIsOpen(true)}
        >
          <SquarePen className="tw-h-3 tw-w-3 tw-text-grey" />
        </Button>
        <DropdownMenuContent className="tw-w-[var(--radix-dropdown-menu-trigger-width)] tw-max-h-[300px] tw-overflow-y-auto">
          {options.map((option) => (
            <DropdownMenuItem
              key={option.id}
              onSelect={() => handleSelect(option.id)}
            >
              {option.display_en}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default observer(CustomDropdownWithIcon);
