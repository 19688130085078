import React, { FC } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../shadcn-components/dialog";
import { InterestButtons } from "../interest-buttons";
import { Button } from "../../shadcn-components/button";

interface InterestsDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  selectedInterests: string[];
  onConfirm: (interests: string[]) => void;
}

const InterestsDialog: FC<InterestsDialogProps> = ({
  isOpen,
  onOpenChange,
  selectedInterests,
  onConfirm,
}) => {
  const [tempSelectedInterests, setTempSelectedInterests] =
    React.useState<string[]>(selectedInterests);

  const handleConfirm = () => {
    onConfirm(tempSelectedInterests);
    onOpenChange(false);
  };

  React.useEffect(() => {
    if (isOpen) {
      setTempSelectedInterests(selectedInterests);
    }
  }, [isOpen, selectedInterests]);

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:tw-max-w-[600px] tw-bg-white">
        <DialogHeader>
          <DialogTitle className="text-center text-2xl font-normal">
            What are your interests
          </DialogTitle>
          <p className="text-center text-gray-500 mt-2">
            Could be anything, think about what you really likeeee :)
          </p>
        </DialogHeader>

        <div className="py-4">
          <InterestButtons
            selectedInterestsIds={tempSelectedInterests}
            onInterestsChange={setTempSelectedInterests}
            maxSelectedCount={5}
            layoutType="grid"
          />
        </div>

        <DialogFooter>
          <Button
            type="submit"
            className="w-full bg-blue-600 hover:bg-blue-700 text-white"
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default InterestsDialog;
