import axios from "axios";
import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { z } from "zod";
import authStore from "./auth-store";
import mumbaiIcon from "../static/mumbai.svg";
import delhiIcon from "../static/delhi.svg";
import bengaluruIcon from "../static/bengaluru.svg";
import hyderabadIcon from "../static/hyderabad.svg";
import chennaiIcon from "../static/chennai.svg";
import puneIcon from "../static/pune.svg";
import kolkataIcon from "../static/kolkata.svg";
import ahmedabadIcon from "../static/ahmedabad.svg";
import { ApiError } from "./auth.types";
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const formSchema = z.object({
  accountName: z.string().min(2, "Name must be at least 2 characters long."),
  handle: z
    .string()
    .min(5, "Must be at least 5 characters long")
    .max(15, "Maximum of 15 characters"),
  email: z.string().email("Invalid email address."),
  cities: z.string().optional(),
  profilePicture: z
    .instanceof(File)
    .refine((file) => file.size < 5000000, {
      message: "Your profile picture must be less than 5MB.",
    })
    .optional(),
  dateOfBirth: z
    .date({
      required_error: "A date of birth is required.",
    })
    .refine((date) => date <= new Date(), {
      message: "Date of birth cannot be in the future.",
    }),
  interests: z.string().array().optional(),
  // .min(1, "Please select at least 1 interest.")
  // .max(5, "You can select up to 5 interests."),
  gender: z.string().optional(),
  reasonToJoin: z.string().optional(),
});

export type FormValues = z.infer<typeof formSchema>;
export interface OptionItem {
  display_en: string;
  id: string;
}
interface PresignedUrlResponse {
  uploadUrl: string;
  keyID: string;
}

export interface CityWithIcon {
  name: string;
  id?: string;
  imagePlaceholder: string;
}

class SignUpStore {
  @observable selectedInterests: string[] = [];
  @observable isSubmitting = false;
  @observable submitError = "";
  @observable accessToken = "";
  @observable isLoadingOptionsData = false;
  @observable citiesOptions: OptionItem[] = [];
  @observable interestOptions: OptionItem[] = [];
  @observable genderOptions: OptionItem[] = [];
  @observable signUpReasons: OptionItem[] = [];
  @observable hasInitialDataLoaded = false;
  @observable isUploadingImage = false;
  @observable uploadedImageKey: string | null = null;
  @observable citiesWithIcon: CityWithIcon[] = [];
  // REFRESH_TOKEN_KEY = 'refreshToken';
  ACCESS_TOKEN_KEY = "accessToken";
  @observable error: ApiError | null = null;
  constructor() {
    makeAutoObservable(this);
  }

  @action
  toggleInterest = (interest: string) => {
    if (this.selectedInterests.includes(interest)) {
      this.selectedInterests = this.selectedInterests.filter(
        (i) => i !== interest
      );
    } else {
      this.selectedInterests.push(interest);
    }
  };

  @action
  setAccessToken = (accessToken: string) => {
    this.accessToken = accessToken;
  };

  @action
  handleSubmit = async (data: FormValues) => {
    this.isSubmitting = true;
    this.submitError = "";

    try {
      const jsonPayload: Partial<{
        [K in keyof FormValues]: string | string[] | null;
      }> = {};

      for (const [key, value] of Object.entries(data)) {
        if (value != null && value !== "") {
          if (key === "dateOfBirth" && value instanceof Date) {
            jsonPayload[key as keyof FormValues] = value.toLocaleDateString();
          } else if (key === "profilePicture" && value instanceof File) {
            // TODO:upload base64
            console.warn("File upload should be handled separately");
          } else if (key === "cities") {
            jsonPayload[key] = [value as string];
          } else if (Array.isArray(value)) {
            // jsonPayload[key] = value;
          } else if (typeof value === "string") {
            jsonPayload[key as keyof FormValues] = value;
          }
        }
      }

      const response = await axios.post(`${apiBaseUrl}/users`, jsonPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${this.accessToken}`,
        },
      });
      if (response.status !== 200) throw new Error("Failed to submit form.");
      return true;
    } catch (error) {
      console.error("Error submitting form:", error);
      this.submitError =
        "An error occurred while submitting the form. Please try again.";
    } finally {
      this.isSubmitting = false;
    }
  };

  @action
  getAllClientSideData = async () => {
    if (this.hasInitialDataLoaded) return;

    this.isLoadingOptionsData = true;
    try {
      const response = await axios.get(`${apiBaseUrl}/users/availableFields`, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `${authStore.accessToken}`,
        },
      });
      runInAction(() => {
        const data = response.data.message;
        this.citiesOptions = data["cities"];
        this.interestOptions = data["interests"];
        this.genderOptions = data["gender"];
        this.signUpReasons = data["reasonsToJoin"];
        this.hasInitialDataLoaded = true;
        this.fillCities();
      });
    } catch (error) {
      console.error("Error loading options:", error);
    } finally {
      this.isLoadingOptionsData = false;
    }
  };

  @action
  getOtp = async (phone: string) => {
    const body = {
      phoneNumber: phone,
    };
    try {
      const response = await axios.post(
        `${apiBaseUrl}/auth/request-otp`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status !== 200) throw new Error("Failed to get OTP");
      runInAction(() => {
        const data: RequestOTPResponse = response.data;
        this.setAccessToken(data.otpToken);
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  @action
  submitOTP = async (otp: string) => {
    const body = {
      otpToken: this.accessToken,
      otp,
    };
    try {
      const response = await axios.post(`${apiBaseUrl}/auth/verify-otp`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status !== 200) throw new Error("Failed to verify OTP");
      const data: VerifyOTPResponse = response.data;
      this.setAccessToken(data.accessToken);
      authStore.setAccessToken(data.accessToken);
      return data.signupRequired;
    } catch (error) {
      console.error("Error loading options:", error);
    }
  };

  @action
  isUserHandleAvailable = async (handle: string) => {
    const response = await axios.get(
      `${apiBaseUrl}/users/handleExists?q=${handle}`,
      {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `${authStore.accessToken}`,
        },
      }
    );
    if (response.status !== 200) throw new Error("Request failed");
    const data: HandleExistsResponse = response.data;
    return !data.handleExists;
  };

  @action
  getPresignedUrl = async (): Promise<PresignedUrlResponse | null> => {
    try {
      const response = await axios.post(
        `${apiBaseUrl}/get-image-upload-presigned-url`,
        {
          entityType: "user",
          fileType: "image/jpeg",
          fileDimensions: "500x500",
          fileSize: "24412",
        },
        {
          headers: {
            Authorization: `${authStore.accessToken}`,
          },
        }
      );

      if (response.status !== 200)
        throw new Error("Failed to get presigned URL");
      return response.data;
    } catch (error) {
      console.error("Error getting presigned URL:", error);
      return null;
    }
  };

  @action
  uploadImageWithPresignedUrl = async (file: File): Promise<string | null> => {
    this.isUploadingImage = true;
    try {
      // Get presigned URL
      const presignedData = await this.getPresignedUrl();
      if (!presignedData) throw new Error("Failed to get presigned URL");
      console.log("Uploading file:", {
        fileType: file.type,
        fileSize: file.size,
        uploadUrl: presignedData.uploadUrl,
      });
      // Upload to S3
      const uploadResponse = await axios.put(presignedData.uploadUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total ?? 1)
          );
          console.log(`Upload progress: ${percentCompleted}%`);
        },
      });

      // Save the final image URL
      runInAction(() => {
        this.uploadedImageKey = presignedData.keyID;
      });
      console.log("Upload response:", uploadResponse);

      return presignedData.keyID;
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    } finally {
      runInAction(() => {
        this.isUploadingImage = false;
      });
    }
  };

  @action
  fillCities = () => {
    this.citiesWithIcon = [
      {
        name: "Mumbai",
        imagePlaceholder: mumbaiIcon,
        id: this.citiesOptions.find((c) => c.display_en === "Mumbai")?.id,
      },
      {
        name: "Delhi NCR",
        imagePlaceholder: delhiIcon,
        id: this.citiesOptions.find((c) => c.display_en === "Delhi NCR")?.id,
      },
      {
        name: "Bengaluru",
        imagePlaceholder: bengaluruIcon,
        id: this.citiesOptions.find((c) => c.display_en === "Bengaluru")?.id,
      },
      {
        name: "Hyderabad",
        imagePlaceholder: hyderabadIcon,
        id: this.citiesOptions.find((c) => c.display_en === "Hyderabad")?.id,
      },
      {
        name: "Chennai",
        imagePlaceholder: chennaiIcon,
        id: this.citiesOptions.find((c) => c.display_en === "Chennai")?.id,
      },
      {
        name: "Pune",
        imagePlaceholder: puneIcon,
        id: this.citiesOptions.find((c) => c.display_en === "Pune")?.id,
      },
      {
        name: "Kolkata",
        imagePlaceholder: kolkataIcon,
        id: this.citiesOptions.find((c) => c.display_en === "Kolkata")?.id,
      },
      {
        name: "Ahmedabad",
        imagePlaceholder: ahmedabadIcon,
        id: this.citiesOptions.find((c) => c.display_en === "Ahmedabad")?.id,
      },
    ];
  };
}

const signUpStore = new SignUpStore();
export default signUpStore;

interface RequestOTPResponse {
  otpToken: string;
  optLength: number;
  message: string;
}

// interface VerifyOTPResponse {
//   signupRequired: boolean;
//   message: string;
//   code: string;
// }

interface VerifyOTPResponse {
  accessToken: string;
  refreshToken: string;
  signupRequired: boolean;
}

interface HandleExistsResponse {
  handleExists: boolean;
  message: string;
  code: string;
}
