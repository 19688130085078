import { observer } from "mobx-react-lite";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../shadcn-components/button";
import {
  ArrowLeft,
  ArrowRightFromLine,
  MoreVertical,
  PhoneOutgoing,
  Share2,
} from "lucide-react";
import bhagClubProfile from "../../static/bhag-profile-pic.png";
import { Avatar, AvatarImage } from "@radix-ui/react-avatar";
import {
  CommunityFollowAction,
  communityStore,
} from "../../stores/community-store";
import { LoadingSpinner } from "../../shadcn-components/loading-spinner";
import authStore from "../../stores/auth-store";
import signUpStore from "../../stores/signup-store";
import AvatarCirclesWithFollowers from "./avatar-circles-with-followers";
import SocialMediaSection from "./social-media-links";
import { EventCard } from "./community-events-section";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../shadcn-components/dropdown-menu";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../shadcn-components/alert-dialog";

export const CommunityView: FC = observer(() => {
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);
  const { communityHandle } = useParams<{ communityHandle: string }>();
  const {
    interestOptions,
    citiesOptions,
    getAllClientSideData: getAllOptionsForSignUp,
  } = signUpStore;
  const {
    setCurrentCommunityViewName,
    communityData,
    isProfileLoading,
    getCommunity,
    setProfileLoading,
    eventsData,
    getEventsOfCommunity,
    getAdmins,
    adminsList,
  } = communityStore;

  useEffect(() => {
    const fetchCommunity = async () => {
      if (communityHandle) {
        await getCommunity(communityHandle);
        await getAllOptionsForSignUp();
      }
    };
    fetchCommunity();
  }, [communityHandle, getAdmins, getAllOptionsForSignUp, getCommunity]);

  useEffect(() => {
    const fetchEvents = async () => {
      if (communityData?.id && !isProfileLoading) {
        await getEventsOfCommunity(communityData.id);
        // await getAdmins(communityData.id);
      }
    };
    fetchEvents();
  }, [communityData?.id, isProfileLoading, getEventsOfCommunity, getAdmins]);

  useEffect(() => {
    communityStore.setCurrentCommunityViewName(communityHandle);
  }, [communityHandle, setCurrentCommunityViewName]);

  const navigate = useNavigate();

  const { user } = authStore;

  const followOrUnfollow = useCallback(async () => {
    if (!user?.userId || !communityData?.id) {
      return;
    }
    setProfileLoading(true);
    try {
      await communityStore.followOrUnfollow(
        user.userId,
        communityData.isLoggedInUserFollower
          ? CommunityFollowAction.unFollow
          : CommunityFollowAction.follow
      );
    } catch (error) {
      console.error("Error following/unfollowing community:", error);
    }
  }, [
    communityData?.id,
    communityData?.isLoggedInUserFollower,
    user?.userId,
    setProfileLoading,
  ]);

  const handleFollowClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    await followOrUnfollow();
    if (communityData?.id) {
      await getCommunity(communityData?.handle);
    }
  };

  const handleContactAdmins = () => {
    setIsContactDialogOpen(true);
  };

  if (isProfileLoading) {
    return (
      <div className="tw-flex tw-flex-col tw-items-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="tw-w-full tw-bg-white">
      {/* Header Container */}
      <div className="tw-relative">
        {/* Banner Image */}
        <div className="tw-w-full tw-h-48">
          <img
            src={
              communityData?.bannerImageUrls
                ? `https://jackpass-backend-staging-images-bucket.s3.ap-south-1.amazonaws.com/community-banner-images_100x100/${communityData.bannerImageUrls}`
                : ""
            }
            alt={`${communityData?.title} banner`}
            className="tw-w-full tw-h-full tw-object-cover"
          />
        </div>

        {/* Floating Navigation Buttons */}
        <div className="tw-absolute tw-top-4 tw-left-0 tw-right-0 tw-px-4 tw-flex tw-justify-between tw-items-center">
          <button
            onClick={() => navigate(-1)}
            className="tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-bg-white tw-rounded-full"
          >
            <ArrowLeft className="tw-w-6 tw-h-6" />
          </button>
          <div className="tw-flex tw-items-center tw-gap-2">
            <Button className="tw-flex tw-items-center  tw-bg-white tw-gap-2">
              <Share2 className="tw-w-5 tw-h-5 tw-text-black" />
              <span className="tw-text-black tw-font-semibold tw-text-sm">
                Share
              </span>
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button className="tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-bg-white !tw-text-black !tw-p-0">
                  <MoreVertical className="tw-w-5 tw-h-5" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                align="end"
                className="tw-w-48 tw-border-[#F1F5F9]"
              >
                <DropdownMenuItem onClick={() => {}}>
                  <PhoneOutgoing />
                  Contact Admins
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => {}}
                  className="tw-text-red-600"
                >
                  <ArrowRightFromLine />
                  Leave Community
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>

            <AlertDialog
              open={isContactDialogOpen}
              onOpenChange={setIsContactDialogOpen}
            >
              <AlertDialogContent className="tw-max-w-[400px]">
                <AlertDialogHeader>
                  <AlertDialogTitle className="tw-text-xl">
                    Contact
                  </AlertDialogTitle>
                  <AlertDialogCancel className="tw-absolute tw-right-4 tw-top-4 tw-rounded-full tw-p-2 hover:tw-bg-gray-100">
                    ✕
                  </AlertDialogCancel>
                </AlertDialogHeader>
                <div className="tw-p-4 tw-bg-gray-50 tw-rounded-lg">
                  {adminsList.map((admin) => (
                    <div
                      key={admin.userId}
                      className="tw-flex tw-items-center tw-justify-between tw-py-3 first:tw-pt-0 last:tw-pb-0"
                    >
                      <div className="tw-flex tw-items-center tw-gap-3">
                        <Avatar className="tw-w-10 tw-h-10 tw-rounded-full">
                          <AvatarImage
                            src={admin.profilePicture}
                            alt={admin.accountName}
                            className="tw-rounded-full"
                          />
                        </Avatar>
                        <span className="tw-font-medium">
                          {admin.accountName}
                        </span>
                      </div>
                      <button
                        onClick={
                          () => {}
                          // handleWhatsAppClick(admin.whatsappNumber)
                        }
                        className="tw-p-2 tw-rounded-full hover:tw-bg-gray-200"
                      >
                        <img
                          src="/whatsapp-icon.png"
                          alt="WhatsApp"
                          className="tw-w-6 tw-h-6"
                        />
                      </button>
                    </div>
                  ))}
                </div>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        </div>

        <div className="tw-px-4">
          {/* Logo and Join Button Row */}
          <div className="tw-relative -tw-mt-5 tw-flex tw-items-center tw-justify-between">
            <div className="tw-rounded-3xl tw-border-4 tw-border-white">
              <Avatar className="tw-w-[88px] tw-h-[88px] tw-rounded-2xl">
                <AvatarImage
                  src={bhagClubProfile}
                  alt={communityData?.title}
                  className="tw-rounded-2xl"
                />
              </Avatar>
            </div>
            <Button
              variant={
                communityData?.isLoggedInUserFollower ? "outline" : "default"
              }
              size="sm"
              className="!tw-rounded-[38px] !tw-p-4 tw-mt-8"
              onClick={handleFollowClick}
            >
              {communityData?.isLoggedInUserFollower ? "Joined" : "Join Club"}
            </Button>
          </div>

          {/* Handle and Category */}
          <div className="tw-mt-4 tw-flex tw-items-center tw-gap-2 tw-text-xs  tw-mb-2">
            <span>@{communityData?.handle}</span>
            <span>•</span>
            <span>
              {
                interestOptions.find(
                  (i) => i.id === communityData?.categories[0]
                )?.display_en
              }
            </span>
          </div>

          {/* Title */}
          <h1 className="tw-text-2xl tw-font-bold tw-mt-1 tw-text-left tw-mb-3">
            {communityData?.title}
          </h1>

          {/* Stats */}
          <div className="tw-flex tw-items-center tw-gap-2 tw-mt-1 tw-text-sm tw-text-gray-600">
            <AvatarCirclesWithFollowers
              avatarUrls={[
                "https://github.com/shadcn.png",
                "https://github.com/shadcn.png",
                "https://github.com/shadcn.png",
              ]}
              followerCount={communityData?.followerCount}
              onFollowersClick={() => {}}
              showFollowersText={false}
            />
            {communityData?.location && (
              <div>
                <span>•</span>
                <span>📍</span>
                <span>
                  {
                    citiesOptions.find((c) => c.id === communityData?.location)
                      ?.display_en
                  }
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Description */}
      {(communityData?.description || communityData?.socialMedia) && (
        <div className="tw-p-4">
          <p className="tw-text-base tw-text-grey tw-text-left">
            {communityData?.description}
          </p>
          {communityData?.socialMedia && (
            <SocialMediaSection socialMedia={communityData.socialMedia} />
          )}
        </div>
      )}
      <div className="tw-p-4 tw-bg-[#F1F5F9]">
        <div className="tw-grid tw-grid-cols-2 tw-gap-3">
          {Array.isArray(eventsData) && eventsData.length > 0 ? (
            eventsData.map((event) => (
              <EventCard
                key={event.eventId}
                eventData={event}
                communityLogo={communityData?.profileImageUrl}
                communityTitle={communityData?.title}
              />
            ))
          ) : (
            <div className="tw-col-span-2 tw-text-center tw-text-gray-500 tw-py-8">
              No upcoming events
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
