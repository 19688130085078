import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { CityWithIcon } from "./signup-store";
import axios from "axios";
import { EventData } from "../components/events/stores/event-store";
import { Community } from "./community-store";
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export class ExploreStore {
  @observable selectedCityId: string = "delhi_ncr";
  @observable selectedCity: CityWithIcon | null = null;
  @observable selectedTabValue: string = "events";
  @observable isLoadingExploreEvents = false;
  @observable exploreEvents: EventData[] = [];
  @observable isLoadingExploreCommunities = false;
  @observable exploreCommunities: Community[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  @action
  setSelectedCity(city: CityWithIcon) {
    if (city.id) {
      this.selectedCityId = city.id;
      this.selectedCity = city;
    }
    // You can add additional logic here like fetching events for the selected city
  }

  @action
  setTab = (value: string) => {
    this.selectedTabValue = value;
  };

  @action
  selectEventsTab = () => {
    this.setTab("events");
  };

  @action
  selectCommunitiesTab = () => {
    this.setTab("communities");
  };

  @action
  getEvents = async () => {
    const params = {
      dateRangeStart: new Date().toISOString(),
      dateRangeEnd: new Date(
        Date.now() + 7 * 24 * 60 * 60 * 1000
      ).toISOString(),
      city: this.selectedCityId,
    };
    const queryString = new URLSearchParams(params).toString();
    try {
      this.isLoadingExploreEvents = true;
      const response = await axios.get(`${apiBaseUrl}/events?${queryString}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      runInAction(() => {
        this.exploreEvents = response.data.data;
        this.isLoadingExploreEvents = false;
      });
    } catch (error) {
      console.error("Failed to fetch events:", error);
      runInAction(() => {
        this.exploreEvents = [];
        this.isLoadingExploreEvents = false;
      });
    }
  };

  @action
  getCommunities = async () => {
    this.isLoadingExploreCommunities = true;
    const response = await axios.get(`${apiBaseUrl}/communities`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) console.log("Failed to get communities");
    runInAction(() => {
      this.exploreCommunities = response.data.items;
      this.isLoadingExploreCommunities = false;
    });
  };
}

export const exploreStore = new ExploreStore();
