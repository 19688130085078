import { FC } from "react";
import { BackButton } from "./back-button";

export const ContactUs: FC = () => {
  return (
    <div className="tw-p-4 tw-max-w-4xl">
      <div className="tw-flex tw-items-center tw-mb-6">
        <BackButton />
        <h1 className="tw-text-2xl tw-font-bold tw-text-left">Contact Us</h1>
      </div>

      <p className="tw-text-sm tw-text-left"></p>

      <ul className="tw-list-disc tw-pl-4 tw-space-y-2">
        <li className="tw-text-left">Email: xyz@jackpass.com</li>
        <li className="tw-text-left">Phone: +91-XXXXXXXXXX</li>
      </ul>
    </div>
  );
};
