import { observer } from "mobx-react-lite";
import { Badge } from "../shadcn-components/badge";

import verifiedBadge from "../static/blue-check.svg";
import { FC } from "react";
export const VerifiedCheckMark: FC = observer(() => {
  return (
    <Badge variant="secondary" className="!tw-p-0 !tw-m-0 tw-ml-1">
      <img src={verifiedBadge} alt="Verified" className="tw-w-4 tw-h-4" />
    </Badge>
  );
});
