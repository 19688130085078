import { FC, useEffect } from "react";
import { Community } from "../../stores/community-store";
import { observer } from "mobx-react-lite";
import { LoadingSpinner } from "../../shadcn-components/loading-spinner";
import CommunityCard from "./community-card";
import searchStore from "../../stores/search-store";

interface SearchResultsProps {
    searchCommunitiesResult: Community[];
    isLoading?: boolean;
    searchQuery?: string;
  }
  
  const SearchResults: FC<SearchResultsProps> = observer(({ 
    searchCommunitiesResult, 
    isLoading = false,
    searchQuery = ""
  }) => {
    useEffect(() => {
        const query = new URLSearchParams(window.location.search).get('title');
        if (query) {
          searchStore.searchCommunities(query);
        }
      }, []);
    return (
      <div className="tw-px-12 tw-py-8">
        {isLoading ? (
          <div className="tw-flex tw-justify-center tw-py-8">
            <LoadingSpinner />
          </div>
        ) : searchCommunitiesResult.length > 0 ? (
          <>
            <h2 className="tw-text-xl tw-font-semibold tw-mb-6 tw-text-left">
              Search Results {searchQuery && `for "${searchQuery}"`}
            </h2>
            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-6">
              {searchCommunitiesResult.map((community) => (
                <div key={community.id}>
                  <CommunityCard community={community} />
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="tw-text-center tw-py-12 tw-bg-gray-50 tw-rounded-lg">
            <p className="tw-text-gray-500">
              {searchQuery 
                ? `No communities found matching "${searchQuery}"`
                : "No communities found"
              }
            </p>
          </div>
        )}
      </div>
    );
  });
  
  export default SearchResults;