import { useEffect } from "react";
import { Button } from "../../shadcn-components/button";
import { observer } from "mobx-react-lite";
import EditableInput from "../editable-input";
import { CustomTextareaWithIcon } from "../../shadcn-components/custom-textarea-with-icon";
import CommunityImageBanner from "./community-banner-avatar-edit";
import bhagProfilePic from "../../static/bhag-profile-pic.png";
import bhagBanner from "../../static/bhag-banner.jpg";
import { useNavigate, useParams } from "react-router-dom";
import { manageCommunityStore } from "../../stores/manage-community.store";
import { LoadingSpinner } from "../../shadcn-components/loading-spinner";
import {
  ManageCommunityFormValues,
  manageCommunitySchema,
} from "./manage-community.schema";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import signUpStore from "../../stores/signup-store";
import CustomDropdownWithIcon from "../../shadcn-components/custom-dropdown-with-icon";

const ManageCommunityForm = observer(() => {
  const { communityHandle } = useParams<{ communityHandle: string }>();
  const {
    editCommunityData,
    isLoading,
    updateStatus,
    getManageFormData,
    updateField,
  } = manageCommunityStore;
  const { citiesOptions, interestOptions, getAllClientSideData: getAllOptionsForSignUp } =
    signUpStore;

  const { setValue, watch } = useForm<ManageCommunityFormValues>({
    resolver: zodResolver(manageCommunitySchema),
    defaultValues: {
      title: "",
      handle: "",
      description: "",
      location: "",
      categories: [],
      socialMedia: {
        twitter: "",
        instagram: "",
        youtube: "",
      },
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      if (communityHandle) {
        await getManageFormData(communityHandle);
        await getAllOptionsForSignUp();
      }
    };
    fetchData();
  }, [communityHandle, getAllOptionsForSignUp, getManageFormData]);

  useEffect(() => {
    if (editCommunityData) {
      setValue("title", editCommunityData.title);
      setValue("handle", editCommunityData.handle);
      setValue("description", editCommunityData.description || "");
      setValue("location", editCommunityData.location || "");
      setValue("categories", editCommunityData.categories || []);
      setValue("socialMedia", editCommunityData.socialMedia || {});
    }
  }, [editCommunityData, setValue]);

  const handleFieldUpdate = async (
    field: keyof ManageCommunityFormValues,
    value: any
  ) => {
    await updateField(field, value);
    if (communityHandle) {
      await getManageFormData(communityHandle);
    }
  };

  const navigate = useNavigate();

  const handleManageAdmins = () => {
    navigate(`/${editCommunityData?.handle}/manage/admins`);
  };

  if (isLoading) {
    return (
      <div className="tw-flex tw-flex-col tw-items-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="tw-max-w-3xl tw-mx-auto tw-p-6">
      <CommunityImageBanner
        initialBanner={bhagBanner}
        initialAvatar={bhagProfilePic}
      />

      <div className="tw-mt-12 tw-w-full tw-max-w-[343px] md:tw-max-w-[400px] tw-mx-auto tw-space-y-6">
        <EditableInput
          label="Community Name"
          value={watch("title")}
          onSave={(value) => handleFieldUpdate("title", value)}
          status={updateStatus?.field === "title" ? updateStatus : null}
          name="title"
        />

        <EditableInput
          label="Community Username"
          value={watch("handle")}
          onSave={(value) => handleFieldUpdate("handle", value)}
          status={updateStatus?.field === "handle" ? updateStatus : null}
          name="handle"
        />

        <div>
          <label className="tw-block tw-text-left tw-text-sm tw-font-medium tw-text-grey">
            Bio
          </label>
          <CustomTextareaWithIcon
            value={watch("description") || ""}
            onChange={(e) => setValue("description", e.target.value)}
            onSave={(value) => handleFieldUpdate("description", value)}
            className="tw-min-h-[100px]"
          />
          {updateStatus?.field === "description" && (
            <p
              className={`tw-text-sm tw-mt-1 ${
                updateStatus.status === "error"
                  ? "tw-text-red-500"
                  : updateStatus.status === "success"
                  ? "tw-text-green-500"
                  : "tw-text-blue-500"
              }`}
            >
              {updateStatus.message}
            </p>
          )}
        </div>

        <div>
          <div className="tw-flex tw-justify-between tw-items-center tw-mb-1">
            <label className="tw-text-sm tw-font-medium tw-text-grey">
              Location
            </label>
          </div>
          <CustomDropdownWithIcon
            options={citiesOptions}
            value={watch("location") || ""}
            onChange={(value) => {
              setValue("location", value);
              handleFieldUpdate("location", value);
            }}
            onSelect={() => {}}
          />
        </div>

        <div>
          <div className="tw-flex tw-justify-between tw-items-center tw-mb-1">
            <label className="tw-text-sm tw-font-medium tw-text-grey">
              Category
            </label>
          </div>
          <CustomDropdownWithIcon
            options={interestOptions}
            value={watch("categories")?.[0] || ""}
            onChange={(value) => {
              setValue("location", value);
              handleFieldUpdate("categories", value);
            }}
            onSelect={() => {}}
          />
        </div>

        <EditableInput
          label="Twitter"
          value={watch("socialMedia.twitter") || ""}
          onSave={(value) =>
            handleFieldUpdate("socialMedia", { twitter: value })
          }
          status={updateStatus?.field === "socialMedia" ? updateStatus : null}
          name="socialMedia.twitter"
        />

        <EditableInput
          label="Instagram"
          value={watch("socialMedia.instagram") || ""}
          onSave={(value) =>
            handleFieldUpdate("socialMedia", { instagram: value })
          }
          status={updateStatus?.field === "socialMedia" ? updateStatus : null}
          name="socialMedia.instagram"
        />

        <EditableInput
          label="YouTube"
          value={watch("socialMedia.youtube") || ""}
          onSave={(value) =>
            handleFieldUpdate("socialMedia", { youtube: value })
          }
          status={updateStatus?.field === "socialMedia" ? updateStatus : null}
          name="socialMedia.youtube"
        />

        <Button
          variant="outline"
          className="tw-mt-6 tw-w-full"
          onClick={handleManageAdmins}
        >
          Manage Admins
        </Button>
      </div>
    </div>
  );
});

export default ManageCommunityForm;
