import { useEffect, useState } from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandList,
  CommandItem,
  CommandInput,
} from "../../../shadcn-components/command";
import { Check, ChevronsUpDown } from "lucide-react";
import { useDebounce } from "use-debounce";
import {
  AddressSuggestion,
  getAddressSuggestions,
} from "../api/address/autocomplete-service";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../shadcn-components/popover";
import { Button } from "../../../shadcn-components/button";
import { CommandLoading } from "cmdk";
import { cn } from "../../../lib/utils";

interface AddressInputProps {
  onSelect: (suggestion: AddressSuggestion) => void;
  placeholder?: string;
  defaultValue?: string;
}

export function AddressInput({
  onSelect,
  placeholder,
  defaultValue,
}: AddressInputProps) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defaultValue || "");
  const [selectedItem, setSelectedItem] = useState<AddressSuggestion | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery] = useDebounce(searchQuery, 300);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<AddressSuggestion[]>([]);

  useEffect(() => {
    const fetchItems = async () => {
      if (debouncedQuery.length < 3) {
        setItems([]);
        return;
      }

      setLoading(true);
      try {
        const response = await getAddressSuggestions(debouncedQuery);
        if (response.data) {
          setItems(response.data);
        } else {
          setItems([]);
        }
      } catch (error) {
        console.error(error);
        setItems([]);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [debouncedQuery]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="tw-w-full tw-justify-between tw-truncate !tw-border-custom-border"
        >
          <p className="tw-truncate">
            {selectedItem ? selectedItem.description : placeholder || "Select address..."}
          </p>
          <ChevronsUpDown className="tw-ml-2 tw-h-4 tw-w-4 tw-shrink-0 tw-opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="tw-w-[--radix-popover-trigger-width] tw-p-0 !tw-border-custom-border">
        <Command >
          <CommandInput
            placeholder="Search for address..."
            value={searchQuery}
            onValueChange={setSearchQuery}
          />
          <CommandList className="!tw-border-custom-border">
            {loading ? (
              <CommandLoading>
                <div className="tw-py-6 tw-text-center tw-text-sm">
                  Searching addresses...
                </div>
              </CommandLoading>
            ) : items.length > 0 ? (
              <CommandGroup heading="Addresses" >
                {items.map((item) => (
                  <CommandItem
                    key={item.placeId}
                    value={item.description}
                    onSelect={() => {
                      setSelectedItem(item);
                      setValue(item.description);
                      onSelect(item);
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        "tw-mr-2 tw-h-4 tw-w-4",
                        selectedItem?.placeId === item.placeId ? "tw-opacity-100" : "tw-opacity-0"
                      )}
                    />
                    <div className="tw-flex tw-flex-col">
                      <span className="tw-font-medium">{item.mainText}</span>
                      <span className="tw-text-sm tw-text-muted-foreground">
                        {item.secondaryText}
                      </span>
                    </div>
                  </CommandItem>
                ))}
              </CommandGroup>
            ) : (
              <CommandEmpty>
                {searchQuery.length < 3 
                  ? "Type at least 3 characters to search..."
                  : "No addresses found."}
              </CommandEmpty>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}