import { FC, Fragment, useEffect } from "react";
import {
  Clock,
  Share2,
  ArrowLeft,
  CalendarPlus,
  Ticket,
  Copy,
} from "lucide-react";
import { Button } from "../../../shadcn-components/button";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../shadcn-components/avatar";
import { useNavigate, useParams } from "react-router-dom";
import { eventStore } from "../stores/event-store";
import { LoadingSpinner } from "../../../shadcn-components/loading-spinner";
import { observer } from "mobx-react-lite";
import { MapView } from "./map-view";
import TicketDetailsSection from "./ticket-card";
import { getEventImage } from "../../../utils/image-utils";
import { HostDetailsSection } from "./host-details";
import { Separator } from "../../../shadcn-components/separator";

export const EventDetailsView: FC = observer(() => {
  const { eventId } = useParams<{ eventId: string }>();
  const {
    getEvent,
    eventData,
    isLoadingEvent,
    cancelEvent,
    deleteEvent,
    isCancelling,
    registerForEvent,
    cancelRegistration,
    isRegistering,
    isUnregistering,
    isLoadingAttendees,
    getEventAttendees,
    attendeesList,
  } = eventStore;
  useEffect(() => {
    const fetch = async () => {
      if (eventId) {
        await getEvent(eventId);
        await getEventAttendees(eventId);
      }
    };
    fetch();
  }, [eventId, getEvent, getEventAttendees]);

  const avatarUrls = [
    "https://github.com/shadcn.png",
    "https://github.com/shadcn.png",
    "https://github.com/shadcn.png",
  ];

  const startDate = new Date(eventData?.startDateTime ?? "");
  const endDate = new Date(eventData?.endDateTime ?? "");

  const sameDay =
    startDate.getFullYear() === endDate.getFullYear() &&
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getDate() === endDate.getDate();

  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const navigate = useNavigate();
  const editEventClick = () => {
    navigate(`/e/${eventId}/manage`);
  };

  const onCancelClick = async () => {
    await cancelEvent(eventData?.eventId);
    navigate(`/explore`);
  };
  const onDeleteClick = async () => {
    await deleteEvent(eventData?.eventId);
    navigate(`/explore`);
  };
  const handleRegister = async () => {
    await registerForEvent(eventData?.eventId);
    await getEvent(eventId);
  };
  const formatDuration = (start: Date, end: Date) => {
    const diffHours = (end.getTime() - start.getTime()) / (1000 * 60 * 60);
    return `(${diffHours} hrs)`;
  };

  const buttonClasses =
    "!tw-rounded-lg !tw-border-[#131315] tw-whitespace-nowrap tw-opacity-20";
  return (
    <div className="tw-w-full tw-overflow-x-hidden">
      {isLoadingEvent || isCancelling ? (
        <LoadingSpinner />
      ) : (
        <Fragment>
          <div className="tw-w-full tw-max-w-7xl tw-mx-auto tw-px-4 tw-pt-4 tw-pb-0">
            <div className="tw-relative tw-w-full tw-rounded-lg tw-overflow-hidden">
              <div className="tw-w-full tw-h-[429px]">
                <img
                  src={eventData?.logo ? getEventImage(eventData.logo) : ""}
                  alt={eventData?.name}
                  className="tw-w-full tw-h-full tw-object-cover"
                />
              </div>
              <button
                onClick={() => navigate(-1)}
                className="tw-absolute tw-top-4 tw-left-4 tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-bg-white tw-rounded-full"
              >
                <ArrowLeft className="tw-w-6 tw-h-6" />
              </button>
            </div>
            <div className="tw-mt-4">
              {/* Attendees section */}
              <div className="tw-flex tw-items-center tw-mb-2">
                <div className="tw-flex tw--space-x-2">
                  {attendeesList?.slice(0, 4).map((attendee, index) => (
                    <Avatar
                      key={index}
                      className="tw-w-6 tw-h-6 tw-border-2 tw-border-white"
                    >
                      <AvatarImage
                        src={attendee.profilePicture || ""}
                        alt={attendee.accountName}
                      />
                      <AvatarFallback>{attendee.accountName[0]}</AvatarFallback>
                    </Avatar>
                  ))}
                </div>
                <span className="tw-text-sm tw-font-semibold tw-text-left">
                  {attendeesList?.length || 0} Going
                </span>
              </div>

              {/* Event title */}
              <h1 className="tw-text-xl tw-font-semibold tw-text-left">
                {eventData?.name}
              </h1>

              <div className="tw-my-4 tw-text-gray-600 tw-flex tw-items-center tw-gap-1">
                <div className="tw-flex tw-items-center tw-space-x-4">
                  <div className="tw-flex tw-items-center tw-space-x-1">
                    <Clock className="tw-text-gray-500" size={16} />
                    <span>
                      {sameDay
                        ? startDate.toLocaleDateString(
                            navigator.language,
                            options
                          )
                        : startDate.toLocaleString(navigator.language, options)}
                    </span>
                  </div>
                  {sameDay && ", "}
                  {sameDay && (
                    <span className="!tw-ml-1">{`${startDate.toLocaleTimeString(
                      navigator.language,
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )} - ${endDate.toLocaleTimeString(navigator.language, {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}`}</span>
                  )}
                  {!sameDay && "-"}
                  {!sameDay && (
                    <span className="!tw-ml-1">{`${endDate.toLocaleString(
                      navigator.language,
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )}`}</span>
                  )}
                </div>
              </div>

              <div className="tw-overflow-x-auto">
                <div className="tw-flex tw-gap-2 tw-min-w-min">
                  <Button className="tw-bg-blue-600 hover:tw-bg-blue-700 tw-text-white tw-rounded-[38px]">
                    <Ticket className="tw-w-4 tw-h-4 tw-mr-2" />
                    Register
                  </Button>
                  <Button variant="outline" className={buttonClasses}>
                    <Share2 className="tw-w-4 tw-h-4 tw-mr-2" />
                    Share
                  </Button>
                  <Button variant="outline" className={buttonClasses}>
                    <Copy className="tw-w-4 tw-h-4 tw-mr-2" />
                    Copy Link
                  </Button>
                  <Button variant="outline" className={buttonClasses}>
                    <CalendarPlus className="tw-w-4 tw-h-4 tw-mr-2" />
                    Add to Calendar
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="tw-px-4 tw-my-8">
            <Separator className="tw-bg-gray-200" />
          </div>
          <TicketDetailsSection />
          <div className="tw-w-full tw-bg-[#F1F5F9]">
            <div className="tw-max-w-7xl tw-mx-auto tw-p-4">
              <div className="tw-space-y-6">
                <div className="tw-space-y-4">
                  <div className="tw-h-[200px] tw-rounded-lg tw-overflow-hidden">
                    <MapView />
                  </div>
                  {eventData && (
                    <HostDetailsSection
                      communityHandle={eventData?.communityDetails.handle}
                      communityName={eventData?.communityDetails.title}
                    />
                  )}
                  <h3 className="tw-font-bold tw-text-left tw-text-base">
                    About Event
                  </h3>
                  <p className="tw-text-sm tw-font-medium tw-text-left">
                    {eventData?.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
});
