import { QrCode } from "lucide-react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../../shadcn-components/card";
import { Button } from "../../../shadcn-components/button";

const TicketDetailsSection = () => {
    return (
      <div className="tw-mb-8">
        <h2 className="tw-text-base tw-font-bold tw-mb-3 tw-text-left tw-ml-4">Ticket Details</h2>
        <Card className=" !tw-bg-[#F1F5F9] tw-border-none tw-text-left !tw-m-4">
          <CardHeader className="!tw-pb-0 !tw-px-4 ">
            <CardTitle className="tw-text-base tw-font-semibold tw-mb-2">You're In ✅</CardTitle>
            <CardDescription className="tw-text-sm tw-font-medium">
              A confirmation email has been sent to rahul@gmail.com.
            </CardDescription>
          </CardHeader>
          <CardContent className="!tw-p-4">
            <Button 
              variant="default" 
              className="tw-w-full  tw-text-white hover:tw-bg-blue-700 tw-mb-4 !tw-rounded-[38px] tw-py-3"
            >
              <QrCode className="tw-w-4 tw-h-4 tw-mr-2" />
              View Ticket
            </Button>
            <p className="tw-text-sm tw-text-gray-600">
              No longer able to attend? Notify the host by
              <button className="tw-text-red-500 hover:tw-underline tw-font-medium">
                Cancelling Your Registration
              </button>
            </p>
          </CardContent>
        </Card>
      </div>
    );
  };
  
  export default TicketDetailsSection;