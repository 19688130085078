import { useEffect, useRef, useState } from "react";
import { Button } from "../shadcn-components/button";
import { Input } from "../shadcn-components/input";
import { PhoneOtp } from "./user/otp";
import { Check, SquarePen, Loader2, X } from "lucide-react";
import profileSettingsStore from "../stores/profile-settings-store";

interface UpdateStatus {
  field: string;
  status: "saving" | "success" | "error" | "verifying";
  message?: string;
}

interface PhoneInputWithOTPProps {
  label: string;
  value: string;
  onSave: (value: string) => Promise<string | void>;
  status: UpdateStatus | null | undefined;
  name: string;
}

const PhoneInputWithOTP: React.FC<PhoneInputWithOTPProps> = ({
  label,
  value,
  onSave,
  status,
  name,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [error, setError] = useState<string | null>(null);
  const [showOTP, setShowOTP] = useState(false);
  const [pendingPhoneNumber, setPendingPhoneNumber] = useState<string | null>(
    null
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const isSavingRef = useRef(false);
  const originalValueRef = useRef(value);

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    setInputValue(value);
    originalValueRef.current = value;
  }, [value]);

  const handleEdit = () => {
    setError(null);
    setIsEditing(true);
    setShowOTP(false);
  };

  const handleRevert = () => {
    setInputValue(originalValueRef.current);
    setError(null);
    setIsEditing(false);
    setShowOTP(false);
    setPendingPhoneNumber(null);
  };

  const initiatePhoneVerification = async () => {
    if (inputValue === originalValueRef.current) {
      setIsEditing(false);
      return;
    }

    // Here you would typically make an API call to send the OTP
    // For now, we'll just show the OTP input
    setPendingPhoneNumber(inputValue);
    setShowOTP(true);
    setIsEditing(false);
  };

  const handleOTPComplete = async (otp: string) => {
    if (!pendingPhoneNumber || isSavingRef.current) return;

    isSavingRef.current = true;
    setError(null);

    try {
      const result = await onSave(pendingPhoneNumber);
      if (typeof result === "string") {
        setError(result);
        setInputValue(originalValueRef.current);
      } else {
        originalValueRef.current = pendingPhoneNumber;
      }
    } catch (err) {
      setError("Failed to verify phone number");
      setInputValue(originalValueRef.current);
    } finally {
      isSavingRef.current = false;
      setShowOTP(false);
      setPendingPhoneNumber(null);
      if (profileSettingsStore.updateStatus?.field === "phoneNumber") {
        profileSettingsStore.clearUpdateStatus();
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!isEditing) return;

    if (event.key === "Enter") {
      event.preventDefault();
      initiatePhoneVerification();
    } else if (event.key === "Escape") {
      event.preventDefault();
      handleRevert();
    }
  };

  const handleButtonClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (isEditing) {
      initiatePhoneVerification();
    } else {
      handleEdit();
    }
  };

  const handleBlur = () => {
    if (isEditing && !isSavingRef.current) {
      initiatePhoneVerification();
    }
  };

  const getStatusIcon = () => {
    if (!status || status.field !== name) {
      if (isEditing) {
        return <Check className="tw-h-3 tw-w-3 tw-text-green-500" />;
      }
      return <SquarePen className="tw-h-3 tw-w-3 tw-text-grey" />;
    }

    switch (status.status) {
      case "saving":
      case "verifying":
        return (
          <Loader2 className="tw-h-3 tw-w-3 tw-text-blue-500 tw-animate-spin" />
        );
      case "success":
        return <Check className="tw-h-3 tw-w-3 tw-text-green-500" />;
      case "error":
        return <X className="tw-h-3 tw-w-3 tw-text-red-500" />;
      default:
        return <SquarePen className="tw-h-3 tw-w-3 tw-text-black" />;
    }
  };

  return (
    <div className="tw-w-full">
      <label className="tw-block tw-text-sm tw-font-medium tw-text-grey tw-mb-1 tw-text-left">
        {label}
      </label>
      <div className="tw-relative">
        <Input
          type="text"
          value={inputValue}
          onChange={(e) => isEditing && setInputValue(e.target.value)}
          className={`tw-pr-20 tw-rounded-full tw-w-full ${
            error || (status?.field === name && status?.status === "error")
              ? "tw-border-red-500"
              : "tw-border-gray-300"
          } ${!isEditing ? "tw-bg-gray-50" : ""}`}
          disabled={
            !isEditing ||
            (status?.field === name && status?.status === "saving")
          }
          ref={inputRef}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          readOnly={!isEditing}
        />
        <div className="tw-absolute tw-right-[1px] tw-top-[1px] tw-flex">
          <Button
            variant="link"
            size="icon"
            onClick={handleButtonClick}
            disabled={status?.field === name && status?.status === "saving"}
            type="button"
          >
            {getStatusIcon()}
          </Button>
        </div>
      </div>
      {showOTP && (
        <div className="tw-mt-4 tw-flex tw-flex-col tw-items-center tw-justify-center">
          <p className="tw-text-sm tw-text-gray-600 tw-mb-4 tw-text-center">
            Please enter the verification code sent to
            <br />
            {pendingPhoneNumber}
          </p>
          <PhoneOtp onSubmit={handleOTPComplete} />
        </div>
      )}
      {(error || (status?.field === name && status?.message)) && (
        <p
          className={`tw-text-sm tw-mt-1 tw-text-center ${
            error || status?.status === "error"
              ? "tw-text-red-500"
              : status?.status === "success"
              ? "tw-text-green-500"
              : "tw-text-blue-500"
          }`}
        >
          {error || status?.message}
        </p>
      )}
    </div>
  );
};

export default PhoneInputWithOTP;
