import { FC, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../shadcn-components/card";
import {
  Avatar,
  AvatarImage,
  AvatarFallback,
} from "../../shadcn-components/avatar";
import { Button } from "../../shadcn-components/button";
import { Trash2 } from "lucide-react";
import { manageCommunityStore } from "../../stores/manage-community.store";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { LoadingSpinner } from "../../shadcn-components/loading-spinner";
import { Input } from "../../shadcn-components/input";

export const ManageAdmins: FC = observer(() => {
  const [searchQuery, setSearchQuery] = useState("");
  const {
    searchUsers,
    getAdmins,
    adminsList,
    getManageFormData,
    editCommunityData,
    isLoadingAdminsList,
    deleteAdmin,
    searchResults,
    isSearchingUser,
    clearSearchResults,
    addAdmin,
  } = manageCommunityStore;
  const { communityHandle } = useParams<{ communityHandle: string }>();

  useEffect(() => {
    const fetch = async () => {
      if (communityHandle) {
        await getManageFormData(communityHandle);
      }
      if (editCommunityData?.id) {
        await getAdmins(editCommunityData?.id);
      }
    };
    fetch();
  }, [communityHandle, editCommunityData?.id, getAdmins, getManageFormData]);

  const handleDeleteAdmin = async (id: string) => {
    await deleteAdmin(id);
    await getAdmins(editCommunityData?.id);
  };

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (searchQuery.trim()) {
        console.log("Searching for:", searchQuery);
        await searchUsers(searchQuery);
      } else {
        clearSearchResults();
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [searchQuery, searchUsers, clearSearchResults]);

  const handleSelectUser = async (userId: string) => {
    if (editCommunityData?.id) {
      setSearchQuery("");
      clearSearchResults();
      await addAdmin(userId);
      await getAdmins(editCommunityData.id);
    }
  };

  return (
    <div className="tw-space-y-6 tw-max-w-md tw-mx-auto tw-p-6">
      <Card className="tw-bg-white tw-rounded-lg tw-border-custom-border">
        <CardHeader className="tw-my-0 tw-py-4 tw-px-4">
          <CardTitle className="tw-text-base tw-text-grey tw-text-left">
            Admin Details
          </CardTitle>
        </CardHeader>
        <CardContent className="tw-px-4 tw-pb-4">
          {isLoadingAdminsList ? (
            <LoadingSpinner />
          ) : (
            adminsList.map((admin) => (
              <div
                key={admin.userId}
                className="tw-flex tw-items-center tw-justify-between"
              >
                <div className="tw-flex tw-items-center tw-space-x-1">
                  <Avatar className="!tw-h-8 !tw-w-8 tw-mr-2">
                    <AvatarImage
                      src="https://github.com/shadcn.png"
                      alt="@shadcn"
                    />
                    <AvatarFallback className="tw-bg-gray-200 tw-text-gray-700">
                      {admin?.accountName?.charAt(0)}
                    </AvatarFallback>
                  </Avatar>
                  <span className="tw-text-gray-700 tw-text-base">
                    {admin.accountName}
                  </span>
                </div>
                {!admin.isOwner && (
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleDeleteAdmin(admin.userId)}
                    className="tw-text-red-500"
                  >
                    <Trash2 className="tw-h-3 tw-w-3" />
                  </Button>
                )}
              </div>
            ))
          )}
        </CardContent>
      </Card>

      <div className="tw-space-y-2">
        <h2 className="tw-text-base tw-font-semibold tw-text-left">
          Add new Admin
        </h2>

        {/* Search Input */}
        <div className="tw-relative">
          <Input
            type="text"
            placeholder="Search users..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="tw-w-full tw-px-4 tw-py-2 tw-border tw-border-custom-border tw-rounded-lg tw-text-base focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500"
          />
        </div>
        {searchQuery && (
          <Card className="tw-mt-2 tw-border-custom-border tw-max-h-[300px] tw-overflow-y-auto">
            <CardContent className="!tw-p-0">
              {isSearchingUser ? (
                <div className="tw-p-4 tw-text-center">
                  <LoadingSpinner />
                </div>
              ) : searchResults.length === 0 ? (
                <div className="tw-p-4 tw-text-center tw-text-gray-500">
                  No users found
                </div>
              ) : (
                <div className="tw-divide-y tw-divide-gray-100">
                  {searchResults.map((user) => (
                    <div
                      key={user.userId}
                      className="tw-flex tw-items-center tw-justify-between tw-p-3"
                    >
                      <div className="tw-flex tw-items-center tw-space-x-3">
                        <Avatar className="tw-h-8 tw-w-8">
                          <AvatarFallback>
                            {user.accountName.charAt(0)}
                          </AvatarFallback>
                        </Avatar>
                        <div className="tw-flex tw-flex-col">
                          <span className="tw-text-sm tw-font-medium">
                            {user.accountName}
                          </span>
                          <span className="tw-text-xs tw-text-gray-500">
                            @{user.handle}
                          </span>
                        </div>
                      </div>
                      <Button
                        size="sm"
                        variant="ghost"
                        onClick={() => handleSelectUser(user.userId)}
                        className="tw-text-blue-500 hover:tw-text-blue-600 hover:tw-bg-blue-50"
                      >
                        Add
                      </Button>
                    </div>
                  ))}
                </div>
              )}
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
});
