import React from "react";
import { Community } from "./communities/api/community-api";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "../shadcn-components/select";
import { cn } from "../lib/utils";

interface CommunitySelectProps {
  value: string;
  onValueChange: (value: string) => void;
  onCommunitySelect?: (community: Community) => void;
  error?: string;
  disabled?: boolean;
  isLoading?: boolean;
  communities: Community[];
  label?: string;
}

export const CommunitySelect: React.FC<CommunitySelectProps> = ({
  value,
  onValueChange,
  onCommunitySelect,
  error,
  disabled,
  isLoading,
  communities,
  label = "Community",
}) => {
  return (
    <div>
      <label className="tw-text-left tw-block tw-text-sm tw-font-medium tw-mb-1 tw-text-grey">
        {label}
      </label>
      <Select
        value={value}
        onValueChange={(value) => {
          onValueChange(value);
          if (onCommunitySelect) {
            const selectedCommunity = communities.find(
              (community) => community.id === value
            );
            if (selectedCommunity) {
              onCommunitySelect(selectedCommunity);
            }
          }
        }}
        disabled={disabled}
      >
        <SelectTrigger
          className={cn(
            "!tw-border-custom-border",
            error && "!tw-border-red-500"
          )}
        >
          <SelectValue
            placeholder={
              isLoading ? "Loading communities..." : "Select a community"
            }
          />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>Your Communities</SelectLabel>
            {isLoading ? (
              <div className="tw-px-2 tw-py-4 tw-text-center">
                <div className="tw-flex tw-items-center tw-justify-center tw-gap-2">
                  <svg
                    className="tw-animate-spin tw-h-4 tw-w-4 tw-text-blue-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="tw-opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="tw-opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                  <span className="tw-text-sm tw-text-gray-500">
                    Loading communities...
                  </span>
                </div>
              </div>
            ) : communities.length === 0 ? (
              <div className="tw-px-2 tw-py-4 tw-text-center">
                <span className="tw-text-sm tw-text-gray-500">
                  No communities found
                </span>
              </div>
            ) : (
              communities.map((community) => (
                <SelectItem key={community.id} value={community.id}>
                  {community.title}
                </SelectItem>
              ))
            )}
          </SelectGroup>
        </SelectContent>
      </Select>
      {error && <p className="tw-text-sm tw-text-red-500 tw-mt-1">{error}</p>}
    </div>
  );
};
