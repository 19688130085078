import React, { FC, useEffect, useRef, useState } from "react";
import { Input } from "../../../shadcn-components/input";
import { Button } from "../../../shadcn-components/button";
import { ImageIcon, Upload } from "lucide-react";
import { Textarea } from "../../../shadcn-components/textarea";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../shadcn-components/form";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../shadcn-components/select";
import { observer } from "mobx-react-lite";
import {
  createEventStore,
  eventFormSchema,
  EventFormValues,
} from "../stores/create-event-store";
import { toast } from "../../../hooks/use-toast";
import { DateTimePicker } from "../../../shadcn-components/datetime-picker";
import { cn } from "../../../lib/utils";
import { AddressType } from "./address";
import { AddressInput } from "./address-input";

const EventCreationForm: FC = observer(() => {
  useEffect(() => {
    createEventStore.getAdminCommunities();

    return () => {
      createEventStore.isLoadingCommunities = false;
    };
  }, []);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const methods = useForm<EventFormValues>({
    resolver: zodResolver(eventFormSchema),
    defaultValues: createEventStore.formData,
  });
  useEffect(() => {
    console.log("Current form values:", methods.getValues());
    console.log("Form is valid:", methods.formState.isValid);
  }, [methods, methods.formState.isValid]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedAddress, setSelectedAddress] = useState<AddressType>({
    address1: "",
    address2: "",
    formattedAddress: "",
    city: "",
    region: "",
    postalCode: "",
    country: "",
    lat: 0,
    lng: 0,
  });
  useEffect(() => {
    if (selectedAddress.formattedAddress) {
      // Update location field
      methods.setValue("location", selectedAddress.formattedAddress, {
        shouldValidate: true,
      });
      createEventStore.setFormField(
        "location",
        selectedAddress.formattedAddress
      );

      // Update city field if needed
      if (selectedAddress.city) {
        methods.setValue("city", selectedAddress.city, {
          shouldValidate: true,
        });
        createEventStore.setFormField("city", selectedAddress.city);
      }
    }
  }, [selectedAddress, methods]);
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        createEventStore.setImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerImageUpload = () => {
    fileInputRef.current?.click();
  };

  const onSubmit = async (values: EventFormValues) => {
    console.log("Form submitted with values:", values); // Add this log
    try {
      // Update store with form values
      Object.entries(values).forEach(([key, value]) => {
        createEventStore.setFormField(key as keyof EventFormValues, value);
      });

      // Submit form
      await createEventStore.submitForm();

      toast({
        title: "Success",
        description: "Event created successfully",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: createEventStore.error || "Failed to create event",
        variant: "destructive",
      });
    }
  };
  console.log("Form errors:", methods.formState.errors);
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(e) => {
          console.log("Form onSubmit triggered");
          e.preventDefault();
          methods.handleSubmit(onSubmit)(e);
        }}
        className="tw-mx-auto tw-p-12"
      >
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-6">
          <div className="tw-w-full md:tw-w-[300px] tw-shrink-0">
            <div className="tw-h-[200px] tw-bg-gray-200 tw-rounded-lg tw-flex tw-items-center tw-justify-center tw-overflow-hidden">
              {createEventStore.image ? (
                <img
                  src={createEventStore.image}
                  alt="Event Banner"
                  className="tw-w-full tw-h-full tw-object-cover"
                />
              ) : (
                <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-2">
                  <ImageIcon size={32} className="tw-text-gray-400" />
                  <span className="tw-text-sm tw-text-gray-500">
                    No image uploaded
                  </span>
                </div>
              )}
            </div>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              className="tw-hidden"
              onChange={handleImageUpload}
            />
            <Button
              type="button"
              variant="outline"
              size="sm"
              className="tw-w-full tw-mt-2 tw-flex tw-items-center tw-justify-center tw-space-x-2 !tw-border-custom-border"
              onClick={triggerImageUpload}
              disabled={createEventStore.isSubmitting}
            >
              <Upload size={14} />
              <span>
                {createEventStore.image ? "Change Image" : "Upload Image"}
              </span>
            </Button>
          </div>

          <div className="tw-flex-1 tw-space-y-4">
            <div>
              <label className="tw-text-left tw-block tw-text-sm tw-font-medium tw-mb-1">
                Community
              </label>
              <Select
                value={methods.getValues("communityId")}
                onValueChange={(value) => {
                  methods.setValue("communityId", value, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                  createEventStore.setFormField("communityId", value);

                  // Find selected community and set its location as city
                  const selectedCommunity =
                    createEventStore.adminCommunities.find(
                      (community) => community.id === value
                    );
                  if (selectedCommunity?.location) {
                    methods.setValue("city", selectedCommunity.location, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                    createEventStore.setFormField(
                      "city",
                      selectedCommunity.location
                    );
                  }
                }}
                disabled={createEventStore.isSubmitting}
              >
                <SelectTrigger
                  className={cn(
                    "!tw-border-custom-border",
                    methods.formState.errors.communityId && "!tw-border-red-500"
                  )}
                >
                  <SelectValue
                    placeholder={
                      createEventStore.isLoadingCommunities
                        ? "Loading communities..."
                        : "Select a community"
                    }
                  />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Your Communities</SelectLabel>
                    {createEventStore.isLoadingCommunities ? (
                      <div className="tw-px-2 tw-py-4 tw-text-center">
                        <div className="tw-flex tw-items-center tw-justify-center tw-gap-2">
                          <svg
                            className="tw-animate-spin tw-h-4 tw-w-4 tw-text-blue-500"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="tw-opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            />
                            <path
                              className="tw-opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            />
                          </svg>
                          <span className="tw-text-sm tw-text-gray-500">
                            Loading communities...
                          </span>
                        </div>
                      </div>
                    ) : createEventStore.adminCommunities.length === 0 ? (
                      <div className="tw-px-2 tw-py-4 tw-text-center">
                        <span className="tw-text-sm tw-text-gray-500">
                          No communities found
                        </span>
                      </div>
                    ) : (
                      createEventStore.adminCommunities.map((community) => (
                        <SelectItem key={community.id} value={community.id}>
                          {community.title}
                        </SelectItem>
                      ))
                    )}
                  </SelectGroup>
                </SelectContent>
              </Select>
              {methods.formState.errors.communityId && (
                <p className="tw-text-sm tw-text-red-500 tw-mt-1">
                  {methods.formState.errors.communityId.message}
                </p>
              )}
            </div>

            <input type="hidden" {...methods.register("city")} />
            {methods.formState.errors.city && (
              <p className="tw-text-sm tw-text-red-500 tw-mt-1">
                {methods.formState.errors.city.message}
              </p>
            )}
            <FormField
              control={methods.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="tw-text-left tw-block">
                    Event Name
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter event name"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        createEventStore.setFormField("name", e.target.value);
                      }}
                      disabled={createEventStore.isSubmitting}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={methods.control}
              name="startDateTime"
              render={({ field: { onChange, value } }) => (
                <FormItem>
                  <FormLabel className="tw-text-left tw-block">
                    Start Date
                  </FormLabel>
                  <FormControl>
                    <DateTimePicker
                      value={new Date(value || Date.now())}
                      onChange={(date: Date | undefined) => {
                        onChange(date?.toISOString());
                        createEventStore.setFormField(
                          "startDateTime",
                          date?.toISOString() ?? new Date().toISOString()
                        );
                      }}
                      use12HourFormat
                      timePicker={{ hour: true, minute: true }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={methods.control}
              name="endDateTime"
              render={({ field: { onChange, value } }) => (
                <FormItem>
                  <FormLabel className="tw-text-left tw-block">
                    End Date
                  </FormLabel>
                  <FormControl>
                    <DateTimePicker
                      value={new Date(value || Date.now() + 3600000)}
                      onChange={(date: Date | undefined) => {
                        onChange(date?.toISOString());
                        createEventStore.setFormField(
                          "endDateTime",
                          date?.toISOString() ??
                            new Date(Date.now() + 3600000).toISOString()
                        );
                      }}
                      use12HourFormat
                      timePicker={{ hour: true, minute: true }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div>
              <label className="tw-text-left tw-block tw-text-sm tw-font-medium tw-mb-1">
                Add Event Location
              </label>
              <AddressInput
                onSelect={(suggestion) => {
                  // You get typed suggestion object with all fields
                  methods.setValue("location", suggestion.description, {
                    shouldValidate: true,
                  });
                  createEventStore.setFormField(
                    "location",
                    suggestion.description
                  );
                }}
                placeholder="Search for an address"
                defaultValue={methods.getValues("location")}
              />
              {methods.formState.errors.location && (
                <p className="tw-text-sm tw-text-red-500 tw-mt-1">
                  {methods.formState.errors.location.message}
                </p>
              )}
            </div>
            <input type="tw-hidden tw-m-0 !tw-h-0" {...methods.register("location")} />
            <input type="tw-hidden tw-m-0 !tw-h-0" {...methods.register("city")} />
            <FormField
              control={methods.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="tw-text-left tw-block">
                    Add Description
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Describe your event"
                      className="tw-resize-none tw-h-20"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        createEventStore.setFormField(
                          "description",
                          e.target.value
                        );
                      }}
                      disabled={createEventStore.isSubmitting}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={methods.control}
              name="totalCapacity"
              render={({ field }) => (
                <FormItem>
                  <div className="tw-flex tw-items-center tw-justify-between">
                    <FormLabel className="tw-text-left tw-mb-0">
                      Capacity
                    </FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        placeholder="0"
                        className="tw-max-w-24"
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          createEventStore.setFormField(
                            "totalCapacity",
                            e.target.value
                          );
                        }}
                        disabled={createEventStore.isSubmitting}
                      />
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className="tw-w-full tw-bg-blue-600 hover:tw-bg-blue-700"
              disabled={createEventStore.isSubmitting}
            >
              {createEventStore.isSubmitting
                ? "Creating Event..."
                : "Create Event"}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
});

export default EventCreationForm;
