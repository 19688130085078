import { action, makeAutoObservable, observable, runInAction } from "mobx";
import bhagClubProfile from "../../../static/bhag-profile-pic.png";
import bhagClubBanner from "../../../static/bhag-banner.jpg";
import axios from "axios";
import authStore from "../../../stores/auth-store";
import { Community, communityApi } from "../../communities/api/community-api";
import { toast } from "../../../hooks/use-toast";
export interface Event {
  id: string;
  title: string;
  date: Date;
  description: string;
  organizer: string;
  profilePicture: string;
  bannerImage: string;
  location: string;
}

export interface EventData {
  eventId: string;
  availableCapacity: number;
  createdBy: string;
  status: "active" | "deleted" | "cancelled";
  createdAt: string;
  updatedAt: string;
  communityId: string;
  name: string;
  startDateTime: string;
  endDateTime: string;
  city: string;
  location: string;
  description: string;
  totalCapacity: number;
  price: number;
  visibility: "public" | "private";
  logo?: string;
  communityDetails: Community;
}

export interface AttendeeUser {
  accountName: string;
  handle: string;
  userId: string;
  profilePicture?: string;
}

interface CommunityTitle {
  title: string;
}
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

class EventStore {
  location: string = "delhi_ncr";
  events: Array<Event> = [
    {
      id: "1",
      title: "Delhi Week 1 Run",
      date: new Date(),

      description:
        "Bhag Club is India's spiciest run club, with group runs every weekend across Delhi NCR.",
      organizer: "Bhag Club",
      location: "Delhi NCR",
      profilePicture: bhagClubProfile,
      bannerImage: bhagClubBanner,
    },
    {
      id: "2",
      title: "Delhi Week 1 Run",
      date: new Date(),

      description:
        "Bhag Club is India's spiciest run club, with group runs every weekend across Delhi NCR.",
      organizer: "Bhag Club",
      profilePicture: bhagClubProfile,
      location: "Delhi NCR",
      bannerImage: bhagClubBanner,
    },
    {
      id: "5545",
      title: "Delhi Week 1 Run",
      date: new Date(),

      description:
        "Bhag Club is India's spiciest run club, with group runs every weekend across Delhi NCR.",
      organizer: "Bhag Club",
      profilePicture: bhagClubProfile,
      location: "Delhi NCR",
      bannerImage: bhagClubBanner,
    },
    {
      id: "46",
      title: "Delhi Week 1 Run",
      date: new Date(),

      description:
        "Bhag Club is India's spiciest run club, with group runs every weekend across Delhi NCR.",
      organizer: "Bhag Club",
      profilePicture: bhagClubProfile,
      location: "Delhi NCR",
      bannerImage: bhagClubBanner,
    },
    {
      id: "3",
      title: "Delhi Week 2 Run",
      date: new Date(new Date().getTime() + 86400000),

      description:
        "Bhag Club is India's spiciest run club, with group runs every weekend across Delhi NCR.",
      organizer: "Bhag Club",
      location: "Delhi NCR",
      profilePicture: bhagClubProfile,
      bannerImage: bhagClubBanner,
    },
    {
      id: "4",
      title: "Delhi Week 2 Run",
      date: new Date(new Date().getTime() + 86400000 * 2),
      description:
        "Bhag Club is India's spiciest run club, with group runs every weekend across Delhi NCR.",
      organizer: "Bhag Club",
      location: "Delhi NCR",
      profilePicture: bhagClubProfile,
      bannerImage: bhagClubBanner,
    },
    {
      id: "5",
      title: "Delhi Week 3 Run",
      date: new Date(new Date().getTime() + 86400000 * 2),
      description:
        "Bhag Club is India's spiciest run club, with group runs every weekend across Delhi NCR.",
      organizer: "Bhag Club",
      location: "Delhi NCR",
      profilePicture: bhagClubProfile,
      bannerImage: bhagClubBanner,
    },
    // Add more events here...
  ];

  @observable eventData: EventData | null = null;
  @observable isLoadingEvent = false;
  @observable isLoadingCommunity = false;
  @observable community: CommunityTitle | null = null;
  @observable isCancelling = false;
  @observable isDeleting = false;
  @observable isRegistering = false;
  @observable isUnregistering = false;
  @observable isLoadingExploreEvents = false;
  @observable landingPageEvents: EventData[] = [];
  @observable locationId: string = "delhi";
  @observable isLoadingAttendees = false;
  @observable attendeesList: AttendeeUser[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  getEvent = async (id: string | undefined) => {
    if (!id) {
      console.error("Event ID is undefined.");
    }
    try {
      this.isLoadingEvent = true;
      const response = await axios.get(`${apiBaseUrl}/events/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authStore.accessToken}`,
        },
      });

      runInAction(() => {
        this.eventData = response.data.data;
        if (this.eventData) {
          this.getCommunity(this.eventData?.communityId);
        }
        this.isLoadingEvent = false;
      });
    } catch (error) {
      console.error("Failed to fetch event:", error);
      runInAction(() => {
        this.eventData = null;
        this.isLoadingEvent = false;
      });
    }
  };

  @action
  getCommunity = async (id: string) => {
    this.isLoadingCommunity = true;
    try {
      const communityData = await communityApi.getCommunityById(id);
      runInAction(() => {
        this.community = communityData;
      });
    } catch (error) {
      console.error("Failed to fetch community:", error);
      runInAction(() => {
        this.community = null;
      });
    } finally {
      runInAction(() => {
        this.isLoadingCommunity = false;
      });
    }
  };

  @action
  cancelEvent = async (id: string | undefined) => {
    if (!id) {
      console.log("Not valid Id");
    }
    this.isCancelling = true;
    try {
      const response = await axios.patch(
        `${apiBaseUrl}/events/${id}/cancel`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authStore.accessToken}`,
          },
        }
      );

      if (response.status !== 200) {
        throw new Error(response.data?.message || "Failed to cancel event");
      }
    } catch (error) {
      toast({
        title: "Error",
      });
    } finally {
      runInAction(() => {
        this.isCancelling = false;
      });
    }
  };

  @action
  deleteEvent = async (id: string | undefined) => {
    if (!id) {
      console.log("Not valid Id");
    }
    this.isDeleting = true;
    try {
      const response = await axios.patch(
        `${apiBaseUrl}/events/${id}/delete`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authStore.accessToken}`,
          },
        }
      );
      if (response.status !== 200) {
        throw new Error(response.data?.message || "Failed to delete event");
      }
    } catch (error) {
      toast({
        title: "Error",
      });
    } finally {
      runInAction(() => {
        this.isDeleting = false;
      });
    }
  };

  @action
  registerForEvent = async (id: string | undefined) => {
    if (!id) {
      console.log("Not valid Id");
    }
    this.isRegistering = true;
    try {
      const response = await axios.post(
        `${apiBaseUrl}/events/${id}/register`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authStore.accessToken}`,
          },
        }
      );
      if (response.status !== 200) {
        throw new Error(
          response.data?.message || "Failed to register for event"
        );
      }
    } catch (error) {
      toast({
        title: "Error",
      });
    } finally {
      runInAction(() => {
        this.isRegistering = false;
      });
    }
  };

  @action
  cancelRegistration = async (id: string) => {
    if (!id) {
      console.log("Not valid Id");
    }
    this.isUnregistering = true;
    try {
      const response = await axios.post(
        `${apiBaseUrl}/events/${id}/unregister`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authStore.accessToken}`,
          },
        }
      );
      if (response.status !== 200) {
        throw new Error(
          response.data?.message || "Failed to cancel event registration"
        );
      }
    } catch (error) {
      console.error("Failed to cancel event registration:", error);
    } finally {
      runInAction(() => {
        this.isUnregistering = false;
      });
    }
  };

  @action
  setLocation = (locationId?: string) => {
    if (locationId) {
      this.location = locationId;
      this.getFilteredEvents();
    }
  };

  @action
  getFilteredEvents = async () => {
    const params = {
      dateRangeStart: new Date().toISOString(),
      dateRangeEnd: new Date(
        Date.now() + 7 * 24 * 60 * 60 * 1000
      ).toISOString(),
      city: this.location,
    };
    const queryString = new URLSearchParams(params).toString();
    try {
      this.isLoadingExploreEvents = true;
      const response = await axios.get(`${apiBaseUrl}/events?${queryString}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      runInAction(() => {
        this.landingPageEvents = response.data.data;
        this.isLoadingExploreEvents = false;
      });
    } catch (error) {
      console.error("Failed to fetch event:", error);
      runInAction(() => {
        this.landingPageEvents = [];
        this.isLoadingExploreEvents = false;
      });
    }
  };

  @action
  getEventAttendees = async (id: string | undefined) => {
    if (!id) {
      console.error("Event ID is undefined.");
    }
    try {
      this.isLoadingAttendees = true;
      const response = await axios.get(`${apiBaseUrl}/events/${id}/attendees`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      runInAction(() => {
        this.attendeesList = response.data.data;
        this.isLoadingAttendees = false;
      });
    } catch (error) {
      console.error("Failed to fetch event attendeeds:", error);
      runInAction(() => {
        this.attendeesList = [];
        this.isLoadingAttendees = false;
      });
    }
  };
}

export const eventStore = new EventStore();
