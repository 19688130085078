import { FC } from "react";
import { BackButton } from "./back-button";

export const AboutUs: FC = () => {
  return (
    <div className="tw-p-4 tw-max-w-4xl">
      <div className="tw-flex tw-items-center tw-mb-6">
        <BackButton />
        <h1 className="tw-text-2xl tw-font-bold tw-text-left">
          About Us
        </h1>
      </div>

      <p className="tw-text-sm tw-text-left">
        We foster real-world connections through hobbies, countering the
        isolation caused by social media. We aim to disrupt how offline
        communities are built.
      </p>
    </div>
  );
};
