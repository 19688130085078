import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { SignUpForm } from "./components/user/signup-form";
import { ExploreHub } from "./components/explore-hub";
import CreateCommunityForm from "./components/communities/create-community-form";
import Layout from "./components/layout";
import { ProfileEdit } from "./components/user/profile-edit";
import { CommunityView } from "./components/communities/community-profile-view";
import ManageCommunityForm from "./components/communities/manage-community";
import { ManageAdmins } from "./components/communities/manage-admins";
import { Join } from "./components/user/join";
import EventCreationForm from "./components/events/components/create-event-form";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import authStore from "./stores/auth-store";
import { LoadingSpinner } from "./shadcn-components/loading-spinner";
import { EventDetailsView } from "./components/events/components/event-details-view";
import SearchResults from "./components/communities/search-results";
import searchStore from "./stores/search-store";
import ManageEventForm from "./components/events/components/manage-event";
import { GuestList } from "./components/events/components/guests-list";
import { ExploreContent } from "./explore/components/explore-content";
import { Landing } from "./components/landing/landing";
import signupStore from "./stores/signup-store";
import { AboutUs } from "./components/landing/about-us";
import TermsAndConditions from "./components/landing/terms-and-conditions";
import PrivacyPolicy from "./components/landing/privacy-policy";
import { ContactUs } from "./components/landing/contact-us";
import { LoginScreen } from "./components/user/join2";
import { OTPVerificationScreen } from "./components/user/otp-verification";
import { ProfileForm } from "./components/user/onboarding-profile";

const AUTHENTICATED_ROUTES = ["me", "c/new", "events/create"];

const ProtectedRoute = observer(
  ({ children }: { children: React.ReactNode }) => {
    const { isAuthenticated, isLoading } = authStore;
    const pathname = window.location.pathname.split("/")[1]; // Get first path segment

    if (isLoading) {
      return <LoadingSpinner />;
    }

    if (!isAuthenticated && AUTHENTICATED_ROUTES.includes(pathname)) {
      return <Navigate to="/join" replace />;
    }

    return <>{children}</>;
  }
);

const App = observer(() => {
  const { getAllClientSideData } = signupStore;
  useEffect(() => {
    const initAuth = async () => {
      const token = authStore.getAccessToken();
      if (token) {
        // await authStore.getUser();
      }
    };
    initAuth();
    getAllClientSideData();
  }, [getAllClientSideData]);

  if (authStore.isLoading) {
    return (
      <div className="tw-flex tw-justify-center tw-items-center tw-h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/*"
            element={
              <Routes>
                <Route path="register" element={<ProfileForm />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/terms" element={<TermsAndConditions />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route
                  path="*"
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <Routes>
                          <Route path="join" element={<LoginScreen />} />
                          <Route
                            path="verify"
                            element={<OTPVerificationScreen />}
                          />
                          <Route path="/" element={<Landing />} />
                          <Route
                            path="explore"
                            // element={<ExploreHub view="events" />}
                            element={<ExploreContent />}
                          />
                          <Route
                            path="c"
                            element={<ExploreHub view="communities" />}
                          />
                          <Route path="me" element={<ProfileEdit />} />
                          <Route
                            path="c/new"
                            element={<CreateCommunityForm />}
                          />
                          <Route
                            path=":communityHandle"
                            element={<CommunityView />}
                          />
                          <Route
                            path=":communityHandle/manage"
                            element={<ManageCommunityForm />}
                          />
                          <Route
                            path=":communityHandle/manage/admins"
                            element={<ManageAdmins />}
                          />
                          <Route
                            path="/e/new"
                            element={<EventCreationForm />}
                          />
                          <Route
                            path="/e/:eventId"
                            element={<EventDetailsView />}
                          />
                          <Route
                            path="/e/:eventId/manage"
                            element={<ManageEventForm />}
                          />
                          <Route
                            path="/e/:eventId/guests"
                            element={<GuestList />}
                          />
                          <Route
                            path="search"
                            element={
                              <SearchResults
                                searchCommunitiesResult={
                                  searchStore.searchResults
                                }
                                isLoading={searchStore.isSearching}
                                searchQuery={searchStore.searchQuery}
                              />
                            }
                          />
                          {/* Redirect to /explore if no match */}
                          <Route
                            path="*"
                            element={<Navigate to="/explore" replace />}
                          />
                        </Routes>
                      </Layout>
                    </ProtectedRoute>
                  }
                />
              </Routes>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
});

export default App;
