import { MapPin } from 'lucide-react';
import { eventStore, Event } from "./events/stores/event-store";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineHeader,
  TimelineIcon,
  TimelineItem,
} from "../shadcn-components/timeline";
import { GroupedEvents } from './explore-hub';
import { Card, CardContent } from '../shadcn-components/card';
import { Avatar } from '../shadcn-components/avatar';

const MobileEventsExplore = () => {
    const groupEventsByDate = (events: Event[]): GroupedEvents => {
        return events.reduce((acc: GroupedEvents, event) => {
          const dateStr = event.date.toDateString();
          if (!acc[dateStr]) {
            acc[dateStr] = [];
          }
          acc[dateStr].push(event);
          return acc;
        }, {});
    };

  const formatEventTime = (date: Date): string => {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const formatEventDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', day: 'numeric', month: 'long' };
    return date.toLocaleDateString('en-US', options);
  };

  const renderEventCard = (event: Event) => (
    <Card key={event.id} className="tw-mb-2 tw-w-full tw-overflow-hidden tw-border-gray-200">
      <CardContent className="tw-px-4 tw-pt-4 tw-pb-4">
        <div className="tw-flex tw-items-start tw-gap-4">
          <div className="tw-shrink-0">
            <img
              src={event.bannerImage}
              alt={event.title}
              className="tw-w-[91px] tw-h-[91px] tw-object-cover tw-rounded-lg"
            />
          </div>
          <div className="tw-flex-1 tw-min-w-0">
            <p className="tw-text-sm tw-text-left tw-font-semibold">{formatEventTime(event.date)}</p>
            <h3 className="tw-text-lg tw-font-bold tw-mb-1 tw-truncate tw-text-left">{event.title}</h3>
            <div className="tw-flex tw-items-center tw-text-sm tw-text-gray-500 tw-mb-2">
              <img
                src={event.profilePicture}
                alt={event.organizer}
                className="tw-w-4 tw-h-4 tw-rounded-full tw-mr-1"
              />
              <span className="tw-truncate">By {event.organizer}</span>
            </div>
            <div className="tw-flex tw-items-center tw-text-xs tw-text-gray-400 tw-mb-2">
              <MapPin className="tw-w-3 tw-h-3 tw-mr-1" />
              <span className="tw-truncate ">{event.location}</span>
            </div>
            <Avatar className="!tw-w-5 !tw-h-5">
              <img src="https://github.com/shadcn.png" alt={"alt name"} />
            </Avatar>
          </div>
        </div>
      </CardContent>
    </Card>
  );

  const groupedEvents = groupEventsByDate(eventStore.events);

  return (
    <div className="tw-flex tw-flex-col tw-flex-1">
      <Timeline className='tw-mb-0'>
        {Object.entries(groupedEvents).map(([dateStr, events]) => (
          <TimelineItem key={dateStr} className="!tw-p-0">
            <TimelineConnector className="!tw-bg-gray-300 !tw-left-[5px]" />
            <TimelineHeader>
              <TimelineIcon className="!tw-bg-gray-300" />
              <h2 className="tw-text-sm tw-font-semibold tw-text-gray-500 tw-mb-1">
                {formatEventDate(new Date(dateStr))}
              </h2>
            </TimelineHeader>
            <TimelineContent className="!tw-pl-4 tw-pb-0 tw-w-full">
              {events.map(renderEventCard)}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </div>
  );
};

export default MobileEventsExplore;