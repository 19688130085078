import authStore from "../../../stores/auth-store";
import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export interface Community {
  id: string;
  title: string;
  handle: string;
  description: string;
  location: string;
  followerCount: number;
  profileImageUrl: string;
  bannerImageUrls: string;
  categories: string[];
  socialMedia?: {
    twitter?: string;
    instagram?: string;
    youtube?: string;
  };
  isUserNotLoggedIn?: boolean;
  isLoggedInUserAdmin?: boolean;
  isLoggedInUserFollower?: boolean;
}

export interface CommunityFormData {
  title: string;
  handle: string;
  description: string;
}

const getHeaders = (): Record<string, string> => ({
  "Content-Type": "application/json",
});

export const communityApi = {
  getCommunityByHandle: async (handle: string): Promise<Community> => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/communities/handle/${handle}`,
        {
          headers: getHeaders(),
        }
      );
      return response.data.data;
    } catch (error) {
      throw new Error(
        `Failed to fetch community: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
    }
  },

  getCommunityById: async (id: string): Promise<Community> => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/communities/${id}`,
        {
          headers: getHeaders(),
        }
      );
      return response.data.data;
    } catch (error) {
      throw new Error(
        `Failed to fetch community: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
    }
  }, 
};


