import * as z from "zod";

export const manageCommunitySchema = z.object({
  title: z.string().min(1, "Community name is required").max(100),
  handle: z.string().min(1, "Community username is required").max(20),
  description: z.string().max(1000).optional(),
  location: z.string().optional(),
  categories: z.array(z.string()).optional(),
  socialMedia: z
    .object({
      twitter: z.string().max(50).optional(),
      instagram: z.string().max(50).optional(),
      youtube: z.string().max(50).optional(),
    })
    .optional(),
});

export type ManageCommunityFormValues = z.infer<typeof manageCommunitySchema>;
