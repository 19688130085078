import { useNavigate } from "react-router-dom";
import { Button } from "../../shadcn-components/button";
import { FC } from "react";
import { ChevronLeft } from "lucide-react";

export const BackButton: FC = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
      };
    
  return (
    <Button
      variant="secondary"
      size="icon"
      className="tw-mr-4 !tw-rounded-3xl"
      onClick={handleBackClick}
    >
      <ChevronLeft />
    </Button>
  );
};
