import axios from "axios";
import { action, makeAutoObservable, observable, runInAction } from "mobx";

interface User {
  userId: string;
  handle: string;
  profilePicture?: string;
  phoneNumber: string;
  accountName: string;
}

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

class AuthStore {
  ACCESS_TOKEN_KEY = "accessToken";
  @observable accessToken = "";
  @observable user: User | null = null;
  @observable isLoading = false;

  constructor() {
    makeAutoObservable(this);
    this.initializeAuth();
  }

  @action
  private async initializeAuth() {
    const storedToken = localStorage.getItem(this.ACCESS_TOKEN_KEY);
    if (storedToken) {
      this.accessToken = storedToken;
      // await this.getUser();
    }
  }

  @action
  setAccessToken = (accessToken: string) => {
    this.accessToken = accessToken;
    localStorage.setItem(this.ACCESS_TOKEN_KEY, accessToken);
    // this.getUser();
  };

  @action
  getUser = async () => {
    if (!this.accessToken) return;

    try {
      this.isLoading = true;
      const response = await axios.get(`${apiBaseUrl}/users`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${this.accessToken}`,
        },
      });

      runInAction(() => {
        const data = response.data;
        this.user = data;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          this.clearAccessToken();
        }
      });
      console.error("Error loading user:", error);
    }
  };

  @action
  getAccessToken() {
    // If the token is not in memory, try to get it from localStorage
    if (!this.accessToken) {
      const storedToken = localStorage.getItem(this.ACCESS_TOKEN_KEY);
      if (storedToken) {
        this.accessToken = storedToken;
      }
    }
    return this.accessToken;
  }

  @action
  clearAccessToken() {
    this.accessToken = "";
    this.user = null;
    localStorage.removeItem(this.ACCESS_TOKEN_KEY);
  }

  get isAuthenticated() {
    return Boolean(this.getAccessToken());
  }

  @action
  logout = () => {
    this.clearAccessToken();
  };
}

const authStore = new AuthStore();
export default authStore;
