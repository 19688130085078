import { Badge } from "../../shadcn-components/badge";

interface AvatarCirclesWithFollowersProps {
  avatarUrls?: string[];
  followerCount?: number;
  onFollowersClick: () => void;
  className?: string;
  showFollowersText?: boolean;
}

const AvatarCirclesWithFollowers: React.FC<AvatarCirclesWithFollowersProps> = ({
  avatarUrls = [],
  followerCount = 0,
  onFollowersClick,
  className = "",
  showFollowersText = true,
}) => {
  const displayedAvatars = avatarUrls.slice(0, 2);

  return (
    <div className={`tw-flex tw-items-center ${className}`}>
      {followerCount === 0 ? (
        <Badge className="!tw-bg-[#FFE291] !tw-text-black tw-rounded-sm">
          NEW
        </Badge>
      ) : (
        <div className="tw-flex tw--space-x-3 tw-items-center">
          {displayedAvatars.map((url: string, index: number) => (
            <img
              key={index}
              src={url}
              alt={`Follower ${index + 1}`}
              className="tw-w-5 tw-h-5 tw-rounded-full"
            />
          ))}
          <span className="tw-text-sm tw-font-medium !tw-ml-1">
            {followerCount} Members
          </span>
        </div>
      )}
    </div>
  );
};

export default AvatarCirclesWithFollowers;
