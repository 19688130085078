import { AdvancedMarker, APIProvider, Map } from "@vis.gl/react-google-maps";
import { observer } from "mobx-react-lite";
import { FC } from "react";

export const MapView: FC = observer(() => {
  const position = { lat: 53.54992, lng: 10.00678 };
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  if(!apiKey){
    throw new Error("GOOGLE_MAPS_API_KEY is not defined in .env file");
  }

  return (
    <APIProvider apiKey={apiKey}>
      <Map defaultCenter={position} defaultZoom={10} mapId="DEMO_MAP_ID">
        <AdvancedMarker position={position} />
      </Map>
    </APIProvider>
  );
});
