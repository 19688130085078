import React, { useState, ChangeEvent, useRef } from "react";
import { Camera, CircleX } from "lucide-react";

interface CommunityImageBannerProps {
  initialBanner: string;
  initialAvatar: string;
}

type SetImageSrc = React.Dispatch<React.SetStateAction<string>>;

const CommunityImageBanner: React.FC<CommunityImageBannerProps> = ({
  initialBanner,
  initialAvatar,
}) => {
  const [bannerSrc, setBannerSrc] = useState<string>(initialBanner);
  const [avatarSrc, setAvatarSrc] = useState<string>(initialAvatar);
  const bannerInputRef = useRef<HTMLInputElement>(null);
  const avatarInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = (
    event: ChangeEvent<HTMLInputElement>,
    setImageSrc: SetImageSrc
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result && typeof e.target.result === "string") {
          setImageSrc(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
    // Reset the file input
    event.target.value = '';
  };

  const handleClearBanner = () => {
    setBannerSrc("");
    if (bannerInputRef.current) {
      bannerInputRef.current.value = '';
    }
  };

  return (
    <div className="tw-relative tw-w-full tw-max-w-[800px] tw-mx-auto">
      <div className="tw-relative tw-w-full tw-h-[217px] tw-overflow-hidden tw-rounded-lg tw-bg-gray-200">
        {bannerSrc && (
          <img
            src={bannerSrc}
            alt="Community Banner"
            className="tw-w-full tw-h-full tw-object-cover"
          />
        )}
        <div className="tw-absolute tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center">
          <label className="tw-bg-white tw-rounded-full tw-p-2 tw-cursor-pointer tw-mr-2">
            <Camera className="tw-w-6 tw-h-6 tw-text-gray-600" />
            <input
              ref={bannerInputRef}
              type="file"
              className="tw-hidden"
              onChange={(e) => handleImageUpload(e, setBannerSrc)}
              accept="image/*"
            />
          </label>
          {bannerSrc && (
            <button
              onClick={handleClearBanner}
              className="tw-bg-white tw-rounded-full tw-p-2 tw-cursor-pointer"
            >
              <CircleX className="tw-w-6 tw-h-6 tw-text-gray-600" />
            </button>
          )}
        </div>
      </div>
      <div className="tw-absolute tw-bottom-0 tw-left-4 tw-transform tw-translate-y-1/2">
        <div className="tw-relative tw-w-[108px] tw-h-[108px] tw-overflow-hidden tw-rounded-lg tw-bg-gray-200">
          {avatarSrc && (
            <img
              src={avatarSrc}
              alt="Community Avatar"
              className="tw-w-full tw-h-full tw-object-cover"
            />
          )}
          <div className="tw-absolute tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center">
            <label className="tw-bg-white tw-rounded-full tw-p-2 tw-cursor-pointer">
              <Camera className="tw-w-4 tw-h-4 tw-text-gray-600" />
              <input
                ref={avatarInputRef}
                type="file"
                className="tw-hidden"
                onChange={(e) => handleImageUpload(e, setAvatarSrc)}
                accept="image/*"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityImageBanner;