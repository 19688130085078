import { FC } from "react";
import { EventData } from "../events/stores/event-store";
import { observer } from "mobx-react-lite";
import { getCommunityLogo } from "../../utils/image-utils";
import { useNavigate } from "react-router-dom";

interface EventCardProps {
  eventData: EventData;
  communityLogo?: string;
  communityTitle?: string;
}

export const EventCard: FC<EventCardProps> = observer(
  ({ eventData, communityLogo, communityTitle }) => {
    const navigate = useNavigate();

    const formatTime = (dateStr: string) => {
      const date = new Date(dateStr);
      return date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });
    };

    const formatDate = (dateStr: string) => {
      const date = new Date(dateStr);
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);

      if (date.toDateString() === tomorrow.toDateString()) {
        return "Tomorrow";
      }

      return date.toLocaleString("en-US", {
        weekday: "long",
      });
    };

    // Get image URL based on event type
    const getImageUrl = () => {
      return eventData.logo
        ? `https://jackpass-backend-staging-images-bucket.s3.ap-south-1.amazonaws.com/event-images_200x200/${eventData.logo}`
        : "";
    };

    // Get date/time based on event type
    const getDateTime = () => {
      return {
        time: formatTime(eventData.startDateTime),
        date: formatDate(eventData.startDateTime),
      };
    };

    const dateTime = getDateTime();

    return (
      <div
        className="tw-rounded-[8px] tw-overflow-hidden tw-shadow-sm tw-border tw-border-none"
        onClick={() => navigate(`/e/${eventData.eventId}`)}
      >
        <div className="tw-relative">
          <img
            src={getImageUrl()}
            alt={eventData.name}
            className="tw-h-[203px] tw-aspect-[4/3] tw-object-cover tw-mb-3"
          />
          <div className="tw-flex tw-items-center tw-gap-2 tw-mb-2">
            <div className="tw-w-6 tw-h-6 tw-rounded-full tw-overflow-hidden">
              <img
                src={communityLogo ? getCommunityLogo(communityLogo) : ""}
                alt={communityTitle}
                className="tw-w-full tw-h-full tw-object-cover"
              />
            </div>
            <span className="tw-text-xs tw-font-semibold">
              By {communityTitle}
            </span>
          </div>
        </div>
        <div className="tw-p-0">
          <h3 className="tw-text-left tw-font-bold tw-text-sm tw-mb-1 tw-line-clamp-1">
            {eventData.name}
          </h3>
          <div className="tw-flex tw-flex-col tw-gap-1">
            <div className="tw-flex tw-items-center tw-gap-2">
              <span className="tw-text-base">⌚</span>
              <span className="tw-text-gray-600 tw-text-sm">
                {dateTime.time}
              </span>
            </div>
            <div className="tw-flex tw-items-center tw-gap-2">
              <span className="tw-text-base tw-ml-1">📍</span>
              <span className="tw-text-gray-600 tw-text-sm">
                {eventData.location}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
