import axios from "axios";
import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { z } from "zod";
import authStore from "../../../stores/auth-store";
import { Community } from "../../../stores/community-store";
import { normalizeDateToMinute } from "../../../utils/datetime-utils";

export type EventFormValues = {
  communityId: string;
  name: string;
  startDateTime: string; // Changed to string to match schema
  endDateTime: string; // Changed to string to match schema
  city: string;
  location: string;
  description: string;
  totalCapacity: string;
  price?: number;
};

export const eventFormSchema = z.object({
  communityId: z.string({ required_error: "Please select a community" }),
  name: z.string().min(1, "Event name is required"),
  startDateTime: z.preprocess((arg) => {
    if (arg instanceof Date) return arg.toISOString();
    return arg;
  }, z.string().datetime()),
  endDateTime: z
    .preprocess((arg) => {
      if (arg instanceof Date) return arg.toISOString();
      return arg;
    }, z.string().datetime())
    .refine(
      (date) => new Date(date) > new Date(),
      "End date must be in the future"
    ),
  city: z.string().min(1, "City is required"),
  location: z.string().min(1, "Location is required"),
  description: z.string().min(10, "Description must be at least 10 characters"),
  totalCapacity: z.string().optional(),
  price: z.number().optional(),
});

export const validateDates = (data: z.infer<typeof eventFormSchema>) => {
  const startDate = new Date(data.startDateTime);
  const endDate = new Date(data.endDateTime);

  if (endDate <= startDate) {
    throw new Error("End date must be after start date");
  }
  return data;
};

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

class CreateEventStore {
  formData: EventFormValues = {
    communityId: "",
    name: "",
    startDateTime: normalizeDateToMinute(new Date()).toISOString(),
    endDateTime: normalizeDateToMinute(
      new Date(Date.now() + 3600000)
    ).toISOString(),
    city: "",
    location: "",
    description: "",
    totalCapacity: "",
    price: 0,
  };

  @observable isSubmitting = false;
  @observable error: string | null = null;
  @observable image: string | null = null;
  @observable adminCommunities: Community[] = [];
  @observable isLoadingCommunities = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setFormField<K extends keyof EventFormValues>(
    field: K,
    value: EventFormValues[K]
  ) {
    this.formData[field] = value;
  }

  @action
  setImage(imageUrl: string | null) {
    this.image = imageUrl;
  }

  @action
  resetForm() {
    this.formData = {
      communityId: "",
      name: "",
      startDateTime: new Date().toISOString(),
      endDateTime: new Date().toISOString(),
      city: "",
      location: "",
      description: "",
      totalCapacity: "",
    };
    this.image = null;
    this.error = null;
  }

  @action
  submitForm = async () => {
    try {
      this.isSubmitting = true;
      this.error = null;
      // const selectedCommunity = createEventStore.adminCommunities.find(
      //   (community) => community.id === this.formData.communityId
      // );
      // if (selectedCommunity?.location) {
      //   createEventStore.setFormField("city", selectedCommunity.location);
      // }
      const validatedData = eventFormSchema.parse(this.formData);
      validateDates(validatedData);

      const payload = {
        ...validatedData,
        image: this.image, // Include the base64 image string if needed
      };

      const response = await axios.post(
        `${apiBaseUrl}/events`,
        // `api/events`,
        JSON.stringify(payload),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authStore.accessToken}`,
          },
        }
      );

      if (response.status !== 200) {
        throw new Error(response.data?.message || "Failed to create event");
      }

      runInAction(() => {
        const result = response.data;
        this.resetForm();
      });

      // return result;
    } catch (error) {
      runInAction(() => {
        this.error =
          error instanceof Error ? error.message : "An error occurred";
      });
      throw error;
    } finally {
      runInAction(() => {
        this.isSubmitting = false;
      });
    }
  };

  @action
  getAdminCommunities = async () => {
    try {
      this.isLoadingCommunities = true;
      const response = await axios.get(
        `${apiBaseUrl}/communities?isAdmin=true`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authStore.accessToken}`,
          },
        }
      );

      runInAction(() => {
        this.adminCommunities = response.data.items;
        this.isLoadingCommunities = false;
      });
    } catch (error) {
      console.error("Failed to fetch communities:", error);
      runInAction(() => {
        this.adminCommunities = [];
        this.isLoadingCommunities = false;
      });
    }
  };
}

export const createEventStore = new CreateEventStore();
