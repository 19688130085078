import axios from "axios";
import { action, makeAutoObservable, observable, runInAction } from "mobx";
import authStore from "./auth-store";
import { communityApi } from "../components/communities/api/community-api";
import { EventData } from "../components/events/stores/event-store";
import { AdminUser } from "./manage-community.store";

interface CommunityFormData {
  title: string;
  handle: string;
  description: string;
}
export interface Community {
  id: string;
  title: string;
  handle: string;
  description: string;
  location: string;
  followerCount: number;
  profileImageUrl: string;
  bannerImageUrls: string;
  categories: string[];
  socialMedia?: {
    twitter?: string;
    instagram?: string;
    youtube?: string;
    whatsapp?: string;
  };
  isUserNotLoggedIn?: boolean;
  isLoggedInUserAdmin?: boolean;
  isLoggedInUserFollower?: boolean;
}

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export enum CommunityFollowAction {
  follow = "follow",
  unFollow = "unfollow",
}

export interface FollowerUser {
  accountName: string;
  handle: string;
  userId: string;
  profilePicture?: string;
}

class CommunityStore {
  @observable communityData: Community | null = null;
  @observable isProfileLoading = false;
  @observable currentCommunityViewName: string = "community name";
  @observable adminCommunities: Community[] = [];
  @observable isAdminCommunitiesLoading = false;
  @observable followingCommunities: Community[] = [];
  @observable isFollowingCommunitiesLoading = false;
  @observable followers: FollowerUser[] = [];
  @observable isFollowersLoading = false;
  @observable isSubmitting = false;
  @observable isLoadingExploreCommunities = false;
  @observable exploreCommunities: Community[] = [];
  @observable eventsData: EventData[] = [];
  @observable isLoadingAdminsList = false;
  @observable adminsList: AdminUser[] = [];
  
  constructor() {
    makeAutoObservable(this);
  }

  @action
  createCommunity = async (data: CommunityFormData) => {
    this.isSubmitting = true;
    const response = await axios.put(`${apiBaseUrl}/communities`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${authStore.accessToken}`,
      },
    });
    runInAction(() => {
      if (response.status === 201) {
        this.isSubmitting = false;
      }
    });
    return true;
  };

  @action
  setCurrentCommunityViewName(viewName?: string) {
    if (viewName) {
      this.currentCommunityViewName = viewName;
    }
  }

  @action
  setProfileLoading = (isLoading: boolean) =>
    (this.isProfileLoading = isLoading);

  @action
  getCommunity = async (handle: string) => {
    this.isProfileLoading = true;
    try {
      const communityData = await communityApi.getCommunityByHandle(handle);
      runInAction(() => {
        this.communityData = communityData;
      });
    } catch (error) {
      console.error("Failed to fetch community:", error);
      runInAction(() => {
        this.communityData = null;
      });
    } finally {
      runInAction(() => {
        this.isProfileLoading = false;
      });
    }
  };

  @action
  getAdminCommunities = async () => {
    this.isAdminCommunitiesLoading = true;
    const response = await axios.get(`${apiBaseUrl}/communities?isAdmin=true`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${authStore.accessToken}`,
      },
    });
    if (response.status !== 200) console.log("Failed to get communities");
    runInAction(() => {
      this.adminCommunities = response.data.items;
      this.isAdminCommunitiesLoading = false;
    });
  };

  @action
  getEventsOfCommunity = async (id: string) => {
    const response = await axios.get(`${apiBaseUrl}/communities/${id}/events`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) console.log("Failed to get events");
    runInAction(() => {
      this.eventsData = response.data.data;
    });
  };

  @action
  getCommunitiesByFilter = async () => {
    this.isLoadingExploreCommunities = true;
    const response = await axios.get(`${apiBaseUrl}/communities`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) console.log("Failed to get communities");
    runInAction(() => {
      this.exploreCommunities = response.data.items;
      this.isLoadingExploreCommunities = false;
    });
  };

  @action
  getFollowingCommunities = async () => {
    this.isFollowingCommunitiesLoading = true;
    const response = await axios.get(
      `${apiBaseUrl}/communities?isFollowing=true`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authStore.accessToken}`,
        },
      }
    );
    if (response.status !== 200) console.log("Failed to get communities");
    runInAction(() => {
      this.followingCommunities = response.data.items;
      this.isFollowingCommunitiesLoading = false;
    });
  };

  @action
  getCommunityFollowers = async (id: string) => {
    this.isFollowersLoading = true;
    if (id) {
      const response = await axios.get(
        `${apiBaseUrl}/communities/${id}/followers/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authStore.accessToken}`,
          },
        }
      );
      if (response.status !== 200) console.log("Failed to get followers");
      runInAction(() => {
        this.followers = response.data.items;
        this.isFollowersLoading = false;
      });
    }
  };

  @action
  followOrUnfollow = async (userId: string, action: CommunityFollowAction) => {
    if (userId) {
      const response = await axios.put(
        `${apiBaseUrl}/communities/${this.communityData?.id}/followers/${userId}`,
        {
          action: action,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authStore.accessToken}`,
          },
        }
      );
      runInAction(() => {
        console.log(response.status);
      });
    }
  };

  @action
  getAdmins = async (communityId?: string) => {
    if (!communityId) {
      return;
    }
    this.isLoadingAdminsList = true;
    try {
      const response = await axios.get(
        `${apiBaseUrl}/communities/${communityId}/admins`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authStore.accessToken}`,
          },
        }
      );
      runInAction(() => {
        this.adminsList = response.data.items;
      });
    } catch (error) {
      console.error("Error fetching community data:", error);
    } finally {
      runInAction(() => {
        this.isLoadingAdminsList = false;
      });
    }
  };
}

export const communityStore = new CommunityStore();
