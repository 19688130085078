import { FC, useEffect, useState } from "react";
import { Input } from "../shadcn-components/input";
import { CheckCircle2, Loader2, XCircle } from "lucide-react";
import { observer } from "mobx-react-lite";
import signUpStore from "../stores/signup-store";
import { useDebouncedCallback } from "use-debounce";

interface SignUpUsernameFieldProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onCheckingChange?: (isChecking: boolean) => void; // New prop
}

export const SignUpUsernameField: FC<SignUpUsernameFieldProps> = observer(
  ({ value, onChange, className, onKeyDown,onCheckingChange }) => {
    const [isChecking, setIsChecking] = useState(false);
    const [isAvailable, setIsAvailable] = useState<boolean | null>(null);
    const { isUserHandleAvailable } = signUpStore;

    const debouncedCheckUsername = useDebouncedCallback(async (username: string) => {
      if (username.length > 0) {
        setIsChecking(true);
        onCheckingChange?.(true);
        try {
          const available = await isUserHandleAvailable(username);
          setIsAvailable(available);
        } catch (error) {
          console.error("Error checking username availability:", error);
          setIsAvailable(null);
        } finally {
          setIsChecking(false);
          onCheckingChange?.(false);
        }
      } else {
        setIsAvailable(null);
        setIsChecking(false);
        onCheckingChange?.(false);
      }
    }, 500);

    useEffect(() => {
      debouncedCheckUsername(value);
      return () => {
        debouncedCheckUsername.cancel();
      };
    }, [value, debouncedCheckUsername]);

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    };
    return (
      <div className="tw-space-y-2">
        <div className="tw-relative">
          <Input
            type="text"
            placeholder="@username"
            value={value}
            onChange={handleUsernameChange}
            className={`tw-pr-10 ${className}`}
            onKeyDown={onKeyDown}
          />
          <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
            {isChecking && (
              <Loader2 className="tw-h-5 tw-w-5 tw-text-gray-400 tw-animate-spin" />
            )}
            {!isChecking && isAvailable === true && (
              <CheckCircle2 className="tw-h-5 tw-w-5 tw-text-green-600" />
            )}
            {!isChecking && isAvailable === false && (
              <XCircle className="tw-h-5 tw-w-5 tw-text-red-500" />
            )}
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-justify-center tw-text-sm tw-text-gray-500">
          <span>
            {isChecking && "Checking..."}
            {!isChecking && isAvailable === null && "Enter a username"}
          </span>
        </div>
      </div>
    );
  }
);
