import { z } from "zod";

export const profileSettingsSchema = z.object({
  accountName: z.string().min(2, "Name must be at least 2 characters long."),
  phoneNumber: z
    .string()
    .regex(/^\+\d{1,3}\s\d{3,}[-\s]?\d{3,}$/, "Invalid phone number format"),
  dateOfBirth: z
    .date({
      required_error: "A date of birth is required.",
    })
    .refine((date) => date <= new Date(), {
      message: "Date of birth cannot be in the future.",
    }),
  cities: z.string().optional(),
  bio: z.string().max(500, "Bio must be less than 500 characters").optional(),
  interests: z.array(z.string()).optional(),
});

export type ProfileSettingsFormValues = z.infer<typeof profileSettingsSchema>;

export const profileValidation = {
  accountName: z.string().min(2, "Name must be at least 2 characters long."),
  phoneNumber: z
    .string()
    .regex(/^\+\d{1,3}\d{10}$/, "Invalid phone number format"),
  dateOfBirth: z
    .date()
    .refine((date) => date <= new Date(), {
      message: "Date of birth cannot be in the future.",
    })
    .transform((date) => date.toISOString().split("T")[0]),
  cities: z.string().optional(),
  bio: z.string().max(500, "Bio must be less than 500 characters").optional(),
};
