"use client";
import { OTPInput, SlotProps } from "input-otp";
import { cn } from "../../lib/utils";
import { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import { InputOTPSeparator } from "../../shadcn-components/input-otp";
import { LoadingSpinner } from "../../shadcn-components/loading-spinner";

interface PhoneOtpProps {
  onSubmit: (value: string) => Promise<void>;
}
export const PhoneOtp: FC<PhoneOtpProps> = observer(({ onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleComplete = async (value: string) => {
    setIsLoading(true);
    try {
      await onSubmit(value);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <OTPInput
      maxLength={6}
      containerClassName="tw-group tw-flex tw-items-center has-[:disabled]:tw-opacity-30"
      onComplete={handleComplete}
      render={({ slots }) => (
        <>
          <div className="tw-flex">
            {slots.slice(0, 3).map((slot, idx) => (
              <Slot key={idx} {...slot} />
            ))}
          </div>

          <InputOTPSeparator />

          <div className="tw-flex">
            {slots.slice(3).map((slot, idx) => (
              <Slot key={idx} {...slot} />
            ))}
          </div>
        </>
      )}
    />
  );
});

function Slot(props: SlotProps) {
  return (
    <div
      className={cn(
        "tw-relative tw-w-10 tw-h-14 tw-text-[2rem]",
        "tw-flex tw-items-center tw-justify-center",
        "tw-transition-all tw-duration-300",
        "tw-border-gray-600 tw-border-y tw-border-r first:tw-border-l first:tw-rounded-l-md last:tw-rounded-r-md",
        "group-hover:tw-border-accent-foreground/20 group-focus-within:tw-border-accent-foreground/20",
        "tw-outline tw-outline-0 tw-outline-accent-foreground/20",
        { "tw-outline-4 tw-outline-accent-foreground": props.isActive }
      )}
    >
      <div className="group-has-[input[data-input-otp-placeholder-shown]]:tw-opacity-20">
        {props.char ?? props.placeholderChar}
      </div>
      {props.hasFakeCaret && <FakeCaret />}
    </div>
  );
}

function FakeCaret() {
  return (
    <div className="tw-absolute tw-pointer-events-none tw-inset-0 tw-flex tw-items-center tw-justify-center tw-animate-caret-blink">
      <div className="tw-w-px tw-h-8 tw-bg-white" />
    </div>
  );
}
