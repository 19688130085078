import axios from "axios";
import authStore from "../stores/auth-store";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
interface UrlResponse {
  presignedUrl: string;
}
export const getSecureImageUrl = async (
  imageKeyId: string | string
): Promise<UrlResponse | undefined> => {
  if (imageKeyId) {
    try {
      const response = await axios.post(
        `${apiBaseUrl}/get-image-view-presigned-url`,
        {
          entityType: "user",
          fileKeyID: `${imageKeyId}`,
          fileDimensions: "200x200",
        },
        {
          headers: {
            Authorization: `${authStore.accessToken}`,
          },
        }
      );

      return response.data as UrlResponse;
    } catch (error) {
      console.error("Error fetching image:", error);
      throw error;
    }
  }
};

const baseUrl = `https://jackpass-backend-staging-images-bucket.s3.ap-south-1.amazonaws.com`;

export const getCommunityBanner = (imageUrl: string) => {
  return `${baseUrl}/community-banner-images_100x100/${imageUrl}`;
};

export const getCommunityLogo = (imageUrl: string) => {
  return `${baseUrl}/community-logo-images_100x100/${imageUrl}`;
};

export const getEventImage = (imageUrl: string) => {
return `${baseUrl}/event-images_400x400/${imageUrl}`;
};