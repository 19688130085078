import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { PictureUpload } from "../profile-picture";
import { Input } from "../../shadcn-components/input";
import { Textarea } from "../../shadcn-components/textarea";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../shadcn-components/form";
import { Button } from "../../shadcn-components/button";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { communityStore } from "../../stores/community-store";
import { useNavigate } from "react-router-dom";
import { SignUpUsernameField } from "../signup-username";
import { LoadingSpinner } from "../../shadcn-components/loading-spinner";

const formSchema = z.object({
  title: z.string().min(2, {
    message: "Community name must be at least 2 characters.",
  }),
  handle: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
  description: z.string().max(300, {
    message: "Description must not exceed 300 characters.",
  }),
});

const CreateCommunityForm: React.FC = observer(() => {
  const { createCommunity, isSubmitting } = communityStore;
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: "",
      handle: "",
      description: "",
    },
  });
  const navigate = useNavigate();

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const isCreated = await createCommunity(values);
    if (isCreated) {
      navigate(`/${form.getValues().handle}`);
    }
  }

  return (
    <div className="tw-max-w-[400px] tw-mx-auto tw-mt-8">
      {isSubmitting ? (
        <LoadingSpinner />
      ) : (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="tw-space-y-6">
            <div className="tw-flex tw-flex-col tw-items-center tw-space-y-2 tw-mb-6">
              <PictureUpload onImageUpload={() => {}} currentImage={null} />
              <span className="tw-text-sm tw-text-gray-500">
                Add Community Icon
              </span>
            </div>

            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="tw-text-left tw-block tw-text-grey">
                    Community Name
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="Enter Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name={"handle"}
              render={({ field: formField }) => {
                return (
                  <FormItem className="tw-flex tw-flex-col ">
                    <FormLabel className="tw-text-left tw-text-grey">
                      {"Community Handle"}
                    </FormLabel>
                    <FormControl>
                      <SignUpUsernameField
                        value={formField.value as string}
                        onChange={(newValue) => {
                          formField.onChange(newValue);
                        }}
                        onCheckingChange={setIsCheckingUsername}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="tw-text-left tw-block tw-text-grey">
                    Community Description
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Community description"
                      className="tw-resize-none"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button className="tw-w-full tw-mt-4" disabled={isCheckingUsername}>
              Submit
            </Button>
          </form>
        </Form>
      )}
    </div>
  );
});

export default CreateCommunityForm;
