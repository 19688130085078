import React, { FC } from "react";
import { Avatar, AvatarFallback } from "../../../shadcn-components/avatar";
import { Button } from "../../../shadcn-components/button";
import { CheckCircle } from "lucide-react";
import { Card } from "../../../shadcn-components/card";
import { observer } from "mobx-react-lite";
import { Input } from "../../../shadcn-components/input";

interface Guest {
  name: string;
  email: string;
  checkedIn: boolean;
}

export const GuestList: FC = observer(() => {
  const [guests, setGuests] = React.useState<Guest[]>([
    { name: "Anmol Chawla", email: "anmol.chawla@gmail.com", checkedIn: true },
    {
      name: "Gunjan Saini",
      email: "gunjansaini029@gmail.com",
      checkedIn: true,
    },
    { name: "Kartik Sahu", email: "kartiksahu@gmail.com", checkedIn: false },
  ]);

  const handleCheckIn = (email: string) => {
    setGuests(
      guests.map((guest) =>
        guest.email === email ? { ...guest, checkedIn: true } : guest
      )
    );
  };

  return (
    <Card className="tw-w-full tw-max-w-md tw-p-4 tw-mx-auto tw-border-custom-border">
      <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
        <div className="tw-relative tw-w-full">
          <Input
            type="text"
            placeholder="Search event guests..."
            className="tw-w-full tw-p-2 tw-text-sm tw-border-custom-border"
          />
        </div>
      </div>

      <div className="tw-space-y-3">
        {guests.map((guest) => (
          <div
            key={guest.email}
            className="tw-flex tw-items-center tw-justify-between tw-p-2 hover:tw-bg-gray-50 tw-rounded-lg"
          >
            <div className="tw-flex tw-items-center tw-space-x-3">
              <Avatar className="tw-h-10 tw-w-10">
                <AvatarFallback className="tw-bg-purple-100 tw-text-purple-600">
                  {guest.name
                    .split(" ")
                    .map((n) => n[0])
                    .join("")}
                </AvatarFallback>
              </Avatar>
              <div>
                <div className="tw-font-medium">{guest.name}</div>
                <div className="tw-text-sm tw-text-gray-500">{guest.email}</div>
              </div>
            </div>

            {guest.checkedIn ? (
              <span className="tw-px-3 tw-py-1 tw-text-sm tw-text-green-600 tw-bg-green-100 tw-rounded-full">
                Checked In
              </span>
            ) : (
              <Button
                variant="outline"
                size="sm"
                onClick={() => handleCheckIn(guest.email)}
                className="tw-text-gray-600"
              >
                <CheckCircle className="tw-w-4 tw-h-4 tw-mr-1" />
                Check In
              </Button>
            )}
          </div>
        ))}
      </div>
    </Card>
  );
});
