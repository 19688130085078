import React from "react";
import { SquarePen } from "lucide-react";
import { Textarea, TextareaProps } from "./textarea";
import { Button } from "./button";

interface CustomTextareaWithIconProps extends Omit<TextareaProps, "onChange"> {
  onIconClick?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSave?: (value: string) => void;
}

export const CustomTextareaWithIcon: React.FC<CustomTextareaWithIconProps> = ({
  onIconClick,
  onChange,
  onSave,
  ...props
}) => {
  const [isEditing, setIsEditing] = React.useState(false);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onSave?.(e.currentTarget.value);
      setIsEditing(false);
    }
  };

  const handleSave = () => {
    if (isEditing) {
      onSave?.(props.value?.toString() ?? "");
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  };

  return (
    <div className="tw-relative">
      <Textarea
        onChange={onChange}
        onKeyDown={handleKeyDown}
        disabled={!isEditing}
        {...props}
      />
      <Button
        className="tw-absolute tw-right-[1px] tw-top-[1px]"
        variant="link"
        size="icon"
        type="button"
        onClick={handleSave}
      >
        <SquarePen
          className={`tw-h-3 tw-w-3 ${
            isEditing ? "tw-text-blue-500" : "tw-text-grey"
          }`}
        />
      </Button>
    </div>
  );
};
