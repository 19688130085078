// manage-community.store.ts
import { action, makeAutoObservable, observable, runInAction } from "mobx";
import axios from "axios";
import authStore from "./auth-store";
import { ManageCommunityFormValues } from "@/components/communities/manage-community.schema";
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

interface Community {
  id: string;
  title: string;
  handle: string;
  description?: string;
  location?: string;
  categories?: string[];
  socialMedia?: {
    twitter?: string;
    instagram?: string;
    youtube?: string;
  };
}

interface UpdateStatus {
  field: keyof ManageCommunityFormValues;
  status: "saving" | "success" | "error";
  message?: string;
}

class ManageCommunityStore {
  @observable editCommunityData: Community | null = null;
  @observable isLoading = false;
  @observable updateStatus: UpdateStatus | null | undefined;
  @observable isAdminsViewLoading = false;
  @observable isAddingAdmin = false;
  @observable isRemovingAdmin = false;
  @observable isSearchingUser = false;
  @observable searchResults: SearchResult[] = [];
  @observable isLoadingAdminsList = false;
  @observable adminsList: AdminUser[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  getManageFormData = async (handle: string) => {
    this.isLoading = true;
    try {
      const response = await axios.get(
        `${apiBaseUrl}/communities/handle/${handle}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authStore.accessToken}`,
          },
        }
      );
      runInAction(() => {
        this.editCommunityData = response.data.data;
      });
    } catch (error) {
      console.error("Error fetching community data:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  updateField = async (field: keyof ManageCommunityFormValues, value: any) => {
    try {
      runInAction(() => {
        this.updateStatus = { field, status: "saving" };
      });

      if (!this.editCommunityData?.id) {
        runInAction(() => {
          this.updateStatus = {
            field,
            status: "error",
            message: "Community data not found",
          };
        });
        return;
      }

      const updateData: Partial<ManageCommunityFormValues> = {};
      if (field === "socialMedia") {
        updateData.socialMedia = {
          ...this.editCommunityData.socialMedia,
          ...value,
        };
      } else if(field === 'categories'){
        updateData.categories = [value];
      }
       else {
        updateData[field] = value;
      }

      const response = await axios.patch(
        `${apiBaseUrl}/communities/${this.editCommunityData.id}`,
        updateData,
        {
          headers: {
            Authorization: `${authStore.accessToken}`,
          },
        }
      );

      runInAction(() => {
        if (response.data.status === "success") {
          this.editCommunityData = {
            ...this.editCommunityData!,
            ...response.data.updatedCommunity,
          };

          this.updateStatus = {
            field,
            status: "success",
            message: "Successfully updated",
          };

          setTimeout(() => {
            runInAction(() => {
              this.updateStatus = null;
            });
          }, 2000);
        }
      });
    } catch (error: any) {
      runInAction(() => {
        this.updateStatus = {
          field,
          status: "error",
          message: error.response?.data?.message || "Update failed",
        };
      });

      setTimeout(() => {
        runInAction(() => {
          this.updateStatus = null;
        });
      }, 3000);
    }
  };

  @action
  searchUsers = async (query: string) => {
    this.isSearchingUser = true;
    try {
      const response = await axios.get(
        `${apiBaseUrl}/users/search?q=${query}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authStore.accessToken}`,
          },
        }
      );
      runInAction(() => {
        const filtered = response.data.filter((user: any) => !this.adminsList.some((admin) => admin.userId === user.userId));
        this.searchResults = filtered;
      });
    } catch (error) {
      console.error("Error searching users:", error);
    } finally {
      runInAction(() => {
        this.isSearchingUser = false;
      });
    }
  };

  @action
  clearSearchResults = () => {
    this.searchResults = [];
    this.isSearchingUser = false;
  };

  @action
  getAdmins = async (communityId?: string) => {
    if (!communityId) {
      return;
    }
    this.isLoadingAdminsList = true;
    try {
      const response = await axios.get(
        `${apiBaseUrl}/communities/${communityId}/admins`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authStore.accessToken}`,
          },
        }
      );
      runInAction(() => {
        this.adminsList = response.data.items;
      });
    } catch (error) {
      console.error("Error fetching community data:", error);
    } finally {
      runInAction(() => {
        this.isLoadingAdminsList = false;
      });
    }
  };

  @action
  deleteAdmin = async (userId: string) => {
    this.isLoadingAdminsList = true;
    try {
      const response = await axios.put(
        `${apiBaseUrl}/communities/${this.editCommunityData?.id}/admins/${userId}`,
        {
          action: "remove",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authStore.accessToken}`,
          },
        }
      );
      if (response.status !== 200) throw new Error("Failed to delete admin");
    } catch (error) {
      console.error("Error fetching community data:", error);
    } finally {
      runInAction(() => {
        this.isLoadingAdminsList = false;
      });
    }
  };

  @action
  addAdmin = async (userId: string) => {
    this.isLoadingAdminsList = true;
    try {
      const response = await axios.put(
        `${apiBaseUrl}/communities/${this.editCommunityData?.id}/admins/${userId}`,
        {
          action: "add",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authStore.accessToken}`,
          },
        }
      );
      if (response.status !== 200) throw new Error("Failed to delete admin");
    } catch (error) {
      console.error("Error fetching community data:", error);
    } finally {
      runInAction(() => {
        this.isLoadingAdminsList = false;
      });
    }
  };
}
export const manageCommunityStore = new ManageCommunityStore();

export interface SearchResult {
  userId: string;
  handle: string;
  accountName: string;
  profilePicture?: string;
}

export interface AdminUser {
  isOwner: boolean;
  userId: string;
  handle: string;
  profilePicture: string;
  accountName: string;
}
