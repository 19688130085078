import React from "react";
import { LogOut, ArrowLeft } from "lucide-react";
import { Button } from "../../shadcn-components/button";
import { observer } from "mobx-react-lite";
import { DeleteProfileDialog } from "./delete-profile-dialog";
import profileSettingsStore from "../../stores/profile-settings-store";
import EditableInput from "../editable-input";
import { DateTimePicker } from "../../shadcn-components/datetime-picker";
import { profileValidation } from "../../stores/profile-settings.schema";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import authStore from "../../stores/auth-store";
import PhoneInputWithOTP from "../phone-input-with-otp";

const ProfileSettings: React.FC = observer(() => {
  const navigate = useNavigate();
  const {
    getEditProfileData,
    editProfileData,
    updateField,
    updateStatus,
    deleteUser,
  } = profileSettingsStore;

  const handleNameSave = async (value: string) => {
    try {
      const validatedName = profileValidation.accountName.parse(value);
      await updateField("accountName", validatedName);
      await getEditProfileData();
    } catch (error) {
      if (error instanceof z.ZodError) {
        return error.errors[0].message;
      }
      return "Invalid name";
    }
  };

  const handlePhoneSave = async (value: string) => {
    try {
      const validatedPhone = profileValidation.phoneNumber.parse(value);
      await updateField("phoneNumber", validatedPhone);
      await getEditProfileData();
    } catch (error) {
      if (error instanceof z.ZodError) {
        return error.errors[0].message;
      }
      return "Invalid phone number";
    }
  };

  const handleDateSelect = async (date: Date | undefined) => {
    if (date) {
      try {
        const validatedDate = profileValidation.dateOfBirth.parse(date);
        await updateField("dateOfBirth", validatedDate);
        await getEditProfileData();
      } catch (error) {
        if (error instanceof z.ZodError) {
          console.error(error.errors[0].message);
        }
      }
    }
  };

  const handleBack = () => {
    profileSettingsStore.closeSettings();
  };

  const handleDelete = async () => {
    navigate("/");
    await deleteUser();
  };

  const handleLogout = () => {
    authStore.logout();
    navigate("/");
  };

  return (
    <div className="tw-w-full tw-max-w-md tw-mx-auto tw-rounded-lg tw-p-6">
      <div className="tw-flex tw-items-center tw-mb-6">
        <Button
          variant="secondary"
          size="icon"
          onClick={handleBack}
          className="tw-mr-2 !tw-rounded-3xl"
        >
          <ArrowLeft className="tw-h-6 tw-w-6" />
        </Button>
        <h2 className="tw-text-xl tw-font-semibold tw-text-gray-700">
          Profile Settings
        </h2>
      </div>

      <div className="tw-space-y-4">
        <EditableInput
          label="Name"
          name="accountName"
          value={editProfileData?.accountName ?? ""}
          onSave={handleNameSave}
          status={updateStatus}
        />

        <PhoneInputWithOTP
          label="Phone"
          name="phoneNumber"
          value={editProfileData?.phoneNumber ?? ""}
          onSave={handlePhoneSave}
          status={updateStatus}
        />

        <div>
          <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-600 tw-mb-1">
            Date of Birth
          </label>
          <DateTimePicker
            value={
              editProfileData?.dateOfBirth
                ? new Date(editProfileData.dateOfBirth)
                : undefined
            }
            max={new Date()}
            onChange={handleDateSelect}
            hideTime
            className="tw-w-[200px] !tw-rounded-[42px]"
            clearable
          />
        </div>
      </div>

      <div className="tw-mt-8 tw-space-y-4">
        <Button
          variant="secondary"
          className="tw-w-full tw-rounded-full tw-bg-gray-100 tw-text-gray-700 hover:tw-bg-gray-200"
          onClick={handleLogout}
        >
          <LogOut className="tw-h-4 tw-w-4 tw-mr-2" />
          Log out
        </Button>
        <DeleteProfileDialog onDelete={handleDelete} />
      </div>
    </div>
  );
});

export default ProfileSettings;
